.font(@fontName; @urlName: @fontName; @weight: normal; @style: normal) {
  font-family: @fontName;
  src: url('./assets/fonts/@{fontName}.ttf') format('opentype');
  font-weight: @weight;
  font-style: @style;
}

@Regular: 'OpenSans-Regular';
@Bold: 'OpenSans-Bold';
@BoldItalic: 'OpenSans-BoldItalic';
@ExtraBold: 'OpenSans-ExtraBold';
@ExtraBoldItalic: 'OpenSans-ExtraBoldItalic';
@Italic: 'OpenSans-Italic';
@Light: 'OpenSans-Light';
@LightItalic: 'OpenSans-LightItalic';
@Semibold: 'OpenSans-Semibold';
@SemiboldItalic: 'OpenSans-SemiboldItalic';

@font-face {
  .font(@Regular);
}

@font-face {
  .font(@Bold);
}

@font-face {
  .font(@BoldItalic);
}

@font-face {
  .font(@ExtraBold);
}

@font-face {
  .font(@ExtraBoldItalic);
}

@font-face {
  .font(@Italic);
}

@font-face {
  .font(@Light);
}

@font-face {
  .font(@LightItalic);
}

@font-face {
  .font(@Semibold);
}

@font-face {
  .font(@SemiboldItalic);
}
