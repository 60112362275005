.ant-modal > .ant-modal-content {
  //grid-template-columns: min-content;
  //grid-template-rows: min-content;
  //width: 100%;
  //height: 100%;
  //background: #fff;
  //background-color: #fff;
  //background-clip: unset;
  //pointer-events: unset;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  padding: 20px;
  box-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.08),
    0px 20px 32px rgba(128, 140, 154, 0.24);
  border-radius: 12px;
}

.ant-modal > div:first-child {
  display: none;
}

.ant-modal > .ant-modal-content > .ant-modal-close:first-child {
  //display: none;
}

.ant-modal > .ant-modal-content > .ant-modal-header {
  padding: unset;
  color: unset;
  background: unset;
  border-bottom: unset;
  border-radius: unset;
}

.ant-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title {
  margin: unset;
  color: unset;
  font-weight: unset;
  font-size: unset;
  line-height: unset;
  word-wrap: unset;
}

.ant-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 10px;
  height: 28px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.ant-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title {
  height: 28px;
  font-family: 'OpenSans-Semibold';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.ant-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title > div > button {
  width: 24px;
  height: 24px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.ant-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title > div > .ant-btn {
  padding: 0;
  border: unset;
}

.ant-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title > div > .ant-btn-primary {
  color: #000000;
  border-color: unset;
  background: unset;
  text-shadow: unset;
  box-shadow: unset;
}

.ant-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title > div > .ant-btn-circle {
  min-width: 24px;
  background: #f0f0f0;
}

.ant-modal > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}

.ant-modal > .ant-modal-content > .ant-modal-body > .ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  display: flex;
  overflow: hidden;
}

.ant-modal > .ant-modal-content > .ant-modal-body > .ant-tabs > .ant-tabs-nav,
.ant-modal > .ant-modal-content > .ant-modal-body > .ant-tabs > div > .ant-tabs-nav {
  height: 34px;
  align-self: stretch;
}

.ant-modal
  > .ant-modal-content
  > .ant-modal-body
  > .ant-tabs
  > div
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  position: relative;
  font-size: 16px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 28px;
  height: 34px;
}

.ant-modal > .ant-modal-content .ant-tabs-tab-btn {
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
}

.ant-modal
  > .ant-modal-content
  > .ant-modal-body
  > .ant-tabs.ant-tabs-top
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  > .ant-tabs-tab-btn {
  color: #808c9a;
}

.ant-modal
  > .ant-modal-content
  > .ant-modal-body
  > .ant-tabs.ant-tabs-top
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  > .ant-tabs-tab-btn {
  color: #4977e9;
}

.ant-modal > .ant-modal-content > .ant-modal-body > .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-modal > .ant-modal-content > .ant-modal-body > .ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #f0f0f0;
  content: '';
}

.ant-modal > .ant-modal-content > .ant-modal-footer {
  padding: 1rem 0 0 0;
  text-align: right;
  background: transparent;
  border-top: unset;
  border-radius: unset;
}

.ant-modal .ant-list.ant-list-vertical.ant-list-lg.ant-list-split.ant-list-bordered {
  border: 0;
}

.ant-modal .ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 0.7rem 0;
}

.ant-modal .ant-list-bordered.ant-list-lg .ant-list-item > div {
  align-items: center;
}

.ant-modal .ant-list-bordered.ant-list-lg .ant-list-item label {
  height: 32px;
  display: grid;
  grid-template-columns: 32px auto auto;
  grid-template-rows: 1fr;
  padding: 0;
}

.ant-modal .ant-list-item.ant-list-item-no-flex {
  font-family: OpenSans-Semibold, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #808c9a;
}

.ant-modal .ant-list-bordered.ant-list-lg .ant-list-item label > span {
  font-family: OpenSans-Semibold, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  font-style: normal;
  color: #000000;
  padding: 0;
}

.ant-modal .ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}

.ant-modal .ant-modal-content > .ant-modal-close > .ant-modal-close-x > .ant-btn.ant-btn-circle.ant-btn-primary.ant-btn-icon-only {
  min-width: unset;
  width: 24px;
  height: 24px;
  padding: 2.4px 0;
  font-size: 13px;
  vertical-align: -3px;
  background: #f0f0f0;
  color: #000000;
  border: unset;
}

.ant-modal .anticon.anticon-close.ant-modal-close-icon {
  background: #f0f0f0;
  min-width: 24px;
  min-height: 24px;
  display: inline-grid;
  color: #000;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  border-radius: 50%;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-content: center;
  justify-content: center;
}
