.content {
  margin: 10px;
}

.lists {
  height: 100%;
  display: flex;
  overflow-x: auto;
  > * {
    flex: 0 0 auto; // 'rigid' lists
    margin-left: 10px;
  }

  &::after {
    content: '';
    flex: 0 0 10px;
  }
}

.list {
  height: fit-content;
  min-width: 220px;
}
