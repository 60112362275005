@dxHeaderFontSize: 12px;

.dx-button {
  border-radius: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}

.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
  //background-color: white !important;
  //background: white !important;
}

.dx-row.dx-data-row.dx-row-lines.dx-column-lines {
  //background-color: #f8f9fb !important;
  //background: #f8f9fb !important;
}

.dx-datagrid .dx-row.dx-row-alt > td {
  background-color: #f5f5f5;
}

.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
  background: inherit;
}

.dx-datagrid-rowsview .dx-row {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  height: var(--reports-height);
}

.dx-datagrid-content .dx-datagrid-table [class*='column'] + [class*='column']:last-child {
  float: none;
  height: 32px;
}

.dx-datagrid-headers {
  font-family: OpenSans-Semibold;
  color: #808c9a;
  font-size: @dxHeaderFontSize;
  font-weight: 600;
  text-align: center;
}

.dx-row.dx-column-lines.dx-header-row {
  height: 28px;
}

.dx-row > td {
  text-align: center;
}

.dx-widget {
  font-family: OpenSans-Regular !important;
  color: black !important;
  font-size: 12px !important;
}

.dx-header-row > td > .dx-datagrid-text-content {
  white-space: normal;
  vertical-align: middle;
}

.dx-datagrid-text-content.dx-text-content-alignment-left.dx-header-filter-indicator {
  vertical-align: -webkit-baseline-middle;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: none !important;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
  padding: 0 0 0 1.6rem !important;
  border: 1px solid #dedede !important;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder::before {
  border: none !important;
}

.dx-row.dx-column-lines.dx-datagrid-filter-row > div:first-child {
  display: none;
}

.dx-row.dx-column-lines.dx-datagrid-filter-row > div:nth-child(2) {
  display: none;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu {
  padding: 0 0.5rem;
  position: relative;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-menu {
  padding-left: 0.5rem;
  display: block;
}

.dx-menu-base .dx-menu-item-wrapper,
.dx-menu-base .dx-menu-items-container {
  margin: 0;
  padding: 0.07rem 0 !important;
  border: 0;
  outline: 0;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
  //height: 8px !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible .dx-scrollable-scroll-content {
  -webkit-transition:
    background-color 0.5s linear 1s,
    -webkit-box-shadow 0.5s linear 1s;
  transition:
    box-shadow 0.5s linear 1s,
    background-color 0.5s linear 1s,
    -webkit-box-shadow 0.5s linear 1s;
  background-color: #4977e9 !important;
  border-radius: 4px !important;
}

.dx-scrollable-scroll-content {
  background-color: #4977e9 !important;
  border-radius: 4px !important;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
}

.dx-pager .dx-pages .dx-next-button,
.dx-pager .dx-pages .dx-prev-button {
  text-align: center;
  line-height: unset !important;
}

.dx-pages,
.dx-page-sizes {
  font-family: OpenSans-Semibold, sans-serif !important;
  font-style: normal !important;
  height: 32px !important;
  font-weight: 600 !important;
  color: #4977e9 !important;
  font-size: 14px !important;
}

.dx-pager .dx-pages .dx-navigate-button {
  justify-content: center !important;
  align-items: center !important;
  position: static !important;
  height: 32px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
}

.dx-pager .dx-pages .dx-page {
  display: inline-grid !important;
  cursor: pointer;
  padding: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.dx-navigate-button {
  justify-content: center !important;
  align-items: center !important;
  position: static !important;
  height: 32px !important;
  background: transparent !important;
  //border: 1px solid #f0f0f0 !important;
}

.dx-prev-button {
  padding-right: 1rem !important;
  font-size: 14px !important;
  height: 32px !important;
  width: 87px !important;
}

.dx-next-button {
  padding-left: 1rem !important;
  font-size: 14px !important;
  width: 87px !important;
}

.dx-navigate-button.dx-button-disable {
  height: 32px !important;
  color: #808080 !important;
  background: #ffffff !important;
  //border: 1px solid #f0f0f0 !important;
}

.dx-pager .dx-pages .dx-next-button::after {
  display: grid;
  align-items: center;
  height: 32px !important;
  font-family: OpenSans-Semibold, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: #4977e9 !important;
  font-size: 14px !important;
  content: 'Вперед  >' !important;
  border: 1px solid #dedede !important;
  border-radius: 5px !important;
}

.dx-pager .dx-pages .dx-prev-button::after {
  display: grid;
  align-items: center;
  height: 32px !important;
  font-family: OpenSans-Semibold, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: #4977e9 !important;
  font-size: 14px !important;
  content: '<  Назад' !important;
  border: 1px solid #dedede !important;
  border-radius: 5px !important;
}

.dx-pager .dx-pages .dx-next-button::before {
  padding: 0 0.5rem !important;
  display: none !important;
}

.dx-pager .dx-pages .dx-prev-button::before {
  padding: 0 0.5rem !important;
  display: none !important;
}

.dx-page {
  display: inline-grid;
  justify-content: center !important;
  align-items: center !important;
  position: static !important;
  height: 32px !important;
  width: 32px !important;
  border-radius: 0 !important;
  background: #ffffff !important;
  border: 1px solid #dedede !important;
  border-width: 1px 1px 1px 0px !important;
}

.dx-page.dx-selection {
  justify-content: center !important;
  align-items: center !important;
  height: 32px !important;
  width: 32px !important;
  color: #ffffff !important;
  background: #4977e9 !important;
  border: 1px solid #dedede !important;
  border-width: 1px 1px 1px 0px !important;
}

.dx-pages > div:nth-child(3) {
  border-radius: 5px 0 0 5px !important;
  border-width: 1px 1px 1px 1px !important;
}

.dx-pages > div:nth-last-child(-n + 3) {
  border-width: 1px 0px 1px 0px !important;
}

.dx-pages > div:nth-last-child(-n + 2) {
  border-radius: 0 5px 5px 0 !important;
  border-width: 1px 1px 1px 1px !important;
}

.dx-pager .dx-page-sizes .dx-selection {
  color: #ffffff !important;
  background: #4977e9 !important;
}

.dx-pager .dx-page-sizes .dx-page-size {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.dx-pager .dx-page-size {
  border-radius: unset !important;
  border: 1px solid #dedede !important;
  border-width: 1px 1px 1px 0px !important;
}

.dx-pager .dx-page-sizes .dx-page-size:first-child {
  border-radius: 5px 0 0 5px !important;
  border-width: 1px 0px 1px 1px !important;
}

.dx-pager .dx-page-sizes .dx-page-size:last-child {
  border-radius: 0 5px 5px 0 !important;
}

.dx-datagrid-content {
  border: none !important;
  border-width: 0 !important;
  border-radius: 5px !important;
}

.dx-datagrid .dx-row-lines > td {
  border-top: none !important;
  border-bottom: none !important;
}

.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers + .dx-datagrid-rowsview {
  border-top: none !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: none !important;
}

.dx-row.dx-column-lines.dx-datagrid-filter-row {
  height: 36px !important;
}

.dx-datagrid-header-panel {
  border-bottom: none !important;
}

//слудующие два отключают гризонтальную линию между хидером и таблицей
.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers + .dx-datagrid-rowsview {
  border-top: none !important;
}

.dx-datagrid-headers {
  border: none !important;
}

.dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated {
  border-bottom: 1px solid #dedede !important;
  border-radius: 0 0 0 5px !important;
}

.dx-datagrid-pager {
  border-top: none !important;
}

.dx-datagrid-content .dx-datagrid-table [class*='column'] + [class*='column']:last-child {
  float: none;
  height: 36px !important;
}

.dx-row.dx-column-lines.dx-datagrid-filter-row > .dx-editor-cell:not(.dx-command-select) {
  padding-bottom: 0.5rem !important;
}

.dx-page-sizes
  > .dx-show-invalid-badge.dx-selectbox.dx-textbox.dx-texteditor.dx-dropdowneditor-button-visible.dx-editor-outlined.dx-widget.dx-dropdowneditor.dx-dropdowneditor-field-clickable {
  border: 1px solid #dedede !important;
  border-radius: 5px !important;
  width: 51px !important;
}

.dx-show-invalid-badge.dx-selectbox.dx-textbox.dx-texteditor.dx-dropdowneditor-button-visible.dx-editor-outlined.dx-widget.dx-dropdowneditor.dx-dropdowneditor-field-clickable {
  border: 1px solid #dedede !important;
  border-radius: 5px !important;
}

.dx-texteditor-input-container {
  height: 30px;
  width: 30px;
}

.dx-datagrid-focus-overlay {
  border: none !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
  background-color: #e6e6e6 !important;
  color: #333;
}

.dx-datagrid .dx-sort-up::before {
  content: '\2B06' !important;
  color: #4977e9 !important;
}

.dx-datagrid .dx-sort-up::after {
  content: '\2B07' !important;
  color: #dedede !important;
}

.dx-datagrid .dx-sort-down::before {
  content: '\2B06' !important;
  color: #dedede !important;
}

.dx-datagrid .dx-sort-down::after {
  content: '\2B07' !important;
  color: #4977e9 !important;
}

.dx-datagrid .dx-sort-up {
  font: 11px/1 DXIcons !important;
}

.dx-datagrid .dx-sort-down {
  font: 11px/1 DXIcons !important;
}

.dx-datagrid .dx-header-filter::before {
  content: ' ' !important;
  background-color: #4977e9 !important;
  -webkit-mask-image: url(./assets/icons/filter.svg);
  mask-image: url(./assets/icons/filter.svg);
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-datagrid .dx-header-filter.dx-header-filter-empty::before {
  content: ' ' !important;
  background-color: #808c9a !important;
  -webkit-mask-image: url(./assets/icons/filter.svg);
  mask-image: url(./assets/icons/filter.svg);
  height: 11px;
  width: 11px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-datagrid .dx-column-indicators {
  padding: 3px 2px !important;
  float: right !important;
  display: inline-flex !important;
  vertical-align: unset !important;
  white-space: nowrap !important;
  align-items: center !important;
}

.dx-datagrid .dx-column-indicators {
  padding: 3px 2px !important;
  float: right !important;
  display: inline-flex !important;
  vertical-align: unset !important;
  white-space: nowrap !important;
  align-items: center !important;
}

.dx-datagrid-action > .dx-datagrid-text-content.dx-text-content-alignment-right.dx-header-filter-indicator {
  padding: 4px 2px !important;
}

.dx-editor-container {
  padding: 0 8px !important;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu {
  position: relative;
  padding: 0 !important;
}

.dx-row.dx-column-lines.dx-header-row > td > div {
  height: var(--reports-height) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.dx-row > td > span > span > .ant-tag {
  line-height: 18px;
  height: 18px;
}

.dx-row.dx-column-lines.dx-header-row > td > .dx-column-indicators {
  display: inline-flex !important;
}

.dx-sort.dx-sort-none {
  display: inline-block !important;
  width: 12px !important;
}

.dx-datagrid-header-panel {
  display: flex;
  height: 54px;
  align-items: center;
}

.dx-item.dx-toolbar-item > .dx-item-content.dx-toolbar-item-content > .dx-button-mode-contained.dx-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 8px;
  height: 32px;
  background: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.dx-item.dx-toolbar-item.dx-toolbar-button:nth-child(2) {
  border: none !important;
}

.dx-toolbar .dx-toolbar-after {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  //width: 325px;
  height: 32px;
}

.dx-toolbar-after .dx-toolbar-item {
  display: flex;
  align-items: center;
  height: 32px !important;
  padding: 0 !important;
}

.dx-button-mode-contained {
  background-color: #fff;
  border-color: #dedede !important;
  color: #333;
}

.dx-item.dx-toolbar-item.dx-toolbar-label
  > .dx-item-content.dx-toolbar-item-content
  > .dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-icon.dx-button-has-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  height: 28px;
}

.dx-item.dx-toolbar-item
  > .dx-item-content.dx-toolbar-item-content
  > .dx-button-mode-contained.dx-button
  > .ant-btn.ant-btn-text.ant-btn-sm
  > span {
  height: 20px;
  font-family: OpenSans-Semibold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4977e9;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.dx-item.dx-toolbar-item
  > .dx-item-content.dx-toolbar-item-content
  > .dx-button-mode-contained.dx-button
  > .ant-btn.ant-btn-text.ant-btn-sm
  > span:hover {
  color: #2f54eb;
}

.dx-toolbar-text-auto-hide .dx-button .dx-button-content {
  padding: 0 !important;
}

.dx-toolbar-text-auto-hide .dx-button .dx-button-content {
  padding: 0 !important;
}

.dx-button-has-text .dx-button-content {
  padding: 0 !important;
}

.dx-button-has-icon .dx-button-content {
  padding: 0 !important;
}

.dx-button .dx-button-content {
  padding: 0 !important;
}

.dx-button-has-text .dx-button-content {
  padding: 0 !important;
}

.dx-button-has-icon .dx-button-content {
  padding: 0 !important;
}

.dx-button .dx-button-content {
  padding: 0 !important;
}

.dx-item-content.dx-toolbar-item-content
  > .dx-datagrid-toolbar-button.dx-datagrid-export-button.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-icon {
  border: none !important;
  border-radius: 0px !important;
  border-width: 0px !important;
  border-style: solid !important;
}

.dx-item-content.dx-toolbar-item-content
  > .dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-icon.dx-button-has-text {
  border: none !important;
  border-radius: 0px !important;
  border-width: 0px !important;
  border-style: solid !important;
}

.dx-toolbar-text-auto-hide .dx-button .dx-icon {
  width: 16px !important;
  height: 16px !important;
  background-size: 16px 16px !important;
}

.dx-icon-hierarchy::before {
  content: ' ' !important;
  background-color: #4977e9;
  -webkit-mask-image: url(./assets/icons/expand-up.svg);
  mask-image: url(./assets/icons/expand-up.svg);
  //font-size: 11px;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-icon-hierarchy:hover:before {
  background-color: #2f54eb;
}

.dx-icon-clear::before {
  color: #4977e9;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-button-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-icon-clear:hover:before {
  color: #2f54eb;
}

.dx-icon-refresh::before {
  content: ' ' !important;
  background-color: #4977e9;
  -webkit-mask-image: url(./assets/icons/updata.svg);
  mask-image: url(./assets/icons/updata.svg);
  //font-size: 11px;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-icon-refresh:hover:before {
  background-color: #2f54eb;
}

.dx-datagrid-export-button .dx-icon-export-to {
  width: 16px !important;
  height: 16px !important;
  background-size: 16px 16px !important;
}

.dx-icon-export-to::before {
  content: ' ' !important;
  background-color: #4977e9;
  -webkit-mask-image: url(./assets/icons/export.svg);
  mask-image: url(./assets/icons/export.svg);
  //font-size: 11px;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-icon-export-to:hover:before {
  background-color: #2f54eb;
}

.dx-button-has-icon .dx-icon {
  width: 16px !important;
  height: 16px !important;
  background-size: 16px 16px !important;
}

.dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button {
  border: none !important;
  border-radius: 0px !important;
  border-width: 0px !important;
  border-style: solid !important;
  margin-left: 0 !important;
}

.dx-icon-overflow::before {
  content: ' ' !important;
  background-color: #4977e9;
  -webkit-mask-image: url(./assets/icons/menu.svg);
  mask-image: url(./assets/icons/menu.svg);
  //font-size: 11px;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-icon-overflow:hover:before {
  background-color: #2f54eb;
}

.dx-icon-export-excel-button {
  content: ' ' !important;
  background-color: #4977e9;
  -webkit-mask-image: url(./assets/icons/export.svg);
  mask-image: url(./assets/icons/export.svg);
  //font-size: 11px;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dx-icon-export-excel-button:hover:before {
  background-color: #2f54eb;
}

.dx-button-mode-contained.dx-button > .ant-btn-text:hover,
.dx-button-mode-contained.dx-button > .ant-btn-text:focus {
  //color: transparent !important;
  color: transparent;
  background: transparent !important;
  border-color: transparent !important;
}

.dx-button-mode-contained.dx-state-hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated {
  border-bottom: 1px solid #dedede !important;
  border-radius: 0px 0px 5px 5px !important;
  border-collapse: collapse;
}

.dx-datagrid .dx-column-lines > td {
  border-left: 1px solid #ddd !important;
  border-right: 0px solid #ddd !important;
  border-collapse: collapse;
}

.dx-datagrid-content > .dx-datagrid-table.dx-datagrid-table-fixed {
  border: 1px solid #dedede !important;
  border-width: 0px 1px 0px 1px !important;
  border-radius: 0px 0px 5px 5px !important;
}

/*#region DX TABLE FIXES*/
.dx-datagrid-borders {
  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    padding: 8px 4px;
    border-right: 1px solid #dedede !important;
    border-collapse: collapse;
  }

  .dx-datagrid-rowsview .dx-row.dx-row-lines,
  .dx-datagrid-rowsview .dx-row.dx-row-lines > td:first-child,
  .dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed,
  .dx-row.dx-column-lines.dx-header-row > td:first-child,
  .dx-row.dx-column-lines.dx-datagrid-filter-row > td:first-child,
  .dx-datagrid-content .dx-datagrid-table [class*='column'] + [class*='column']:last-child > td:first-child,
  .dx-datagrid-headers .dx-datagrid-table.dx-datagrid-table-fixed tr:first-child td:last-child,
  .dx-datagrid-table.dx-datagrid-table-fixed tr:last-child td:last-child {
    border: none !important;
  }

  .dx-datagrid-rowsview .dx-command-expand:not(.dx-datagrid-group-space) {
    border: solid #dedede !important;
    border-width: 1px 0 !important;
  }

  .dx-datagrid-rowsview .dx-command-expand:last-child {
    border-bottom: none !important;
  }

  .dx-datagrid-filter-panel {
    border-top: none !important;
  }

  .dx-datagrid-rowsview tr:first-child .dx-command-expand {
    border-top: none !important;
  }

  .dx-datagrid-content.dx-datagrid-scroll-container > .dx-datagrid-table.dx-datagrid-table-fixed {
    border: none !important;
    height: 100%;
  }

  div.dx-datagrid-headers {
    border: solid #dedede !important;
    border-width: 1px 1px 0 1px !important;
    border-radius: 5px 5px 0 0 !important;
  }

  .dx-datagrid-rowsview {
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
  }
  .dx-header-row td > .dx-datagrid-text-content {
    padding: 0 !important;
    align-content: center;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .dx-header-row td[colspan] > .dx-datagrid-text-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
/*endregion/*/

.dragableList {
  border: 1px solid #f0f0f0 !important;
  border-radius: 0px 0px 12px 12px !important;
}

.dragableList .dx-datagrid-table.dx-datagrid-table-fixed {
  border: 0 !important;
}

.dragableList .dx-column-lines > td {
  border: 0 !important;
}

.dragableList .dx-datagrid-content.dx-datagrid-scroll-container > .dx-datagrid-table.dx-datagrid-table-fixed {
  border: none !important;
  border-width: 0 !important;
  border-radius: 0 !important;
  border-collapse: unset !important;
}

.dragableList .dx-datagrid-content.dx-datagrid-scroll-container > .dx-datagrid-table.dx-datagrid-table-fixed tr:first-child td:last-child {
  border-right: none !important;
  border-top-right-radius: 0px !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td {
  color: black;
}

.dx-row.dx-column-lines.dx-header-row > td {
  color: #959595;
}

.dx-overlay-wrapper input,
.dx-overlay-wrapper textarea {
  font-family: 'OpenSans-Semibold', sans-serif !important;
  color: #808c9a !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
}

.dx-searchbox .dx-icon-search {
  padding: 7px !important;
  color: #808c9a !important;
}

.dx-searchbox .dx-icon-search {
  padding: 7px !important;
  color: #808c9a !important;
}

.dx-placeholder::after {
  vertical-align: bottom !important;
}

.dx-checkbox-icon {
  border-radius: 5px !important;
  border: 1px solid #dedede !important;
  background-color: #fff;
}

.dx-list-select-all,
.dx-list .dx-empty-message,
.dx-list-item-content {
  color: #000000 !important;
  font-size: 14px !important;
  padding: 13px 5px !important;
}

.dx-list-item-content {
  padding: 13px 11px !important;
}

.dx-scrollview-content > div {
  border-bottom: 1px solid #f0f0f0 !important;
}

.dx-popup-content {
  padding: 0 !important;
}

.dx-list-search {
  margin-bottom: unset !important;
}

.dx-list-item-before-bag.dx-list-select-checkbox-container,
.dx-list-item-before-bag.dx-list-select-radiobutton-container {
  padding: 0 0px 0px 5px !important;
}

.dx-list-search.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-outlined.dx-searchbox.dx-show-clear-button.dx-texteditor-empty.dx-widget {
  padding: 12px 16px !important;
  border: unset !important;
  border-radius: unset !important;
}

.dx-list-search.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-outlined.dx-searchbox.dx-show-clear-button.dx-texteditor-empty.dx-widget
  > .dx-texteditor-container {
  border: 1px solid #ddd !important;
  border-radius: 5px !important;
}

.dx-popup-bottom.dx-toolbar {
  padding: 12px 16px 12px 16px !important;
}

.dx-overlay-content.dx-popup-normal.dx-resizable.dx-dropdowneditor-overlay .dx-scrollable-container {
  height: calc(100% - 27px) !important;
}

.dx-overlay-content.dx-popup-normal.dx-resizable.dx-dropdowneditor-overlay .dx-scrollbar-vertical {
  height: calc(100% - 27px) !important;
}

.dx-overlay-content.dx-popup-normal.dx-resizable.dx-dropdowneditor-overlay {
  height: 347px !important;
}

.dx-overlay-content.dx-popup-normal.dx-resizable.dx-dropdowneditor-overlay .dx-toolbar-after {
  overflow: hidden !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  left: 0px !important;
  padding: 0 !important;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.dx-overlay-content.dx-popup-normal.dx-resizable.dx-dropdowneditor-overlay
  .dx-item-content.dx-toolbar-item-content
  > .dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text {
  height: 32px !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 5px !important;
}

.dx-overlay-content.dx-popup-normal.dx-resizable.dx-dropdowneditor-overlay
  .dx-item-content.dx-toolbar-item-content
  > .dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text
  .dx-button-content {
  display: grid !important;
  align-items: center !important;
  font-family: 'OpenSans-Semibold', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  border: none !important;
  border-radius: 0px;
}

.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text[aria-label*='ОК'] {
  background: #4976e9 !important;
  color: #fff !important;
}

.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text[aria-label*='ОК'] > .dx-button-content {
  border: 1px solid #4976e9;
  border-radius: 5px;
}

.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text[aria-label*='Отменить'] > .dx-button-content {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text[aria-label*='ОК'] > .dx-button-content > .dx-button-text {
  font-size: 0px !important;
}

.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text[aria-label*='ОК']
  > .dx-button-content
  > .dx-button-text:after {
  font-size: 14px !important;
  content: 'Применить' !important;
}

.dx-button.dx-button-normal.dx-button-mode-contained.dx-widget.dx-button-has-text[aria-label*='Отменить'] {
  background: #fff !important;
  color: #4976e9 !important;
}

.dx-overlay-content.dx-popup-normal.dx-resizable.dx-dropdowneditor-overlay .dx-item.dx-toolbar-item.dx-toolbar-button:first-child {
  order: 1;
}

.dx-overlay-content.dx-popup-normal.dx-resizable.dx-dropdowneditor-overlay .dx-item.dx-toolbar-item.dx-toolbar-button:last-child {
  order: -1;
}

.dx-item.dx-toolbar-item.dx-toolbar-button > .dx-item-content.dx-toolbar-item-content > .dx-button-mode-contained.dx-button {
  border-radius: 0px !important;
  border-width: 0px !important;
}

.dx-toolbar-after .dx-button-mode-contained.dx-button > .ant-btn.ant-btn-text.ant-btn-sm {
  height: 32px !important;
  border-radius: 5px !important;
  border: 1px #f0f0f0 !important;
  border-style: solid !important;
}

.dx-toolbar-after .ant-btn.ant-btn-default.ant-btn-sm {
  height: 32px !important;
  border-radius: 5px !important;
  border: 1px #f0f0f0 !important;
  border-style: solid !important;
  line-height: unset !important;
  font-family: 'OpenSans-Semibold', sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  align-items: center !important;
  text-align: center !important;
  color: #4977e9 !important;
}

.dx-pager .dx-pages .dx-info {
  opacity: 1 !important;
}

.dragableList .dx-row.dx-column-lines.dx-datagrid-filter-row > .dx-editor-cell:not(.dx-command-select) {
  padding-bottom: 0 !important;
}

.dx-datagrid-filter-row td .dx-editor-container .dx-filter-range-content {
  border: 1px solid #dedede !important;
  border-radius: 4px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor,
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor-container {
  border: unset !important;
}
