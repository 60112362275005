.holdsPage {
  .holdsDataGrid {
    height: calc(100vh - 128px);
  }
}

.holds-edit-form-modal {
  max-width: 100%;

  .holds-edit-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .holds-edit-form > .ant-row > .ant-col {
    margin-top: 20px;
  }

  .holds-edit-form > .ant-row > .ant-col > *:nth-child(1n + 2) {
    margin-top: 20px;
  }
}
