.content {
  .listItem {
    padding-inline: 16px;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      background-color: #f1f1f1 !important;
    }
  }
}
