.templates {
  display: flex;
  gap: 6px;
  align-items: center;
}

.content {
  min-width: 250px;
}

.title {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 22px;
}

.list {
  max-height: 200px;
  position: relative;
  margin: 0 -12px 8px -12px;
  padding: 8px 12px;
  border-bottom: 1px solid #ededed;

  .template {
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    transition: 0.2s all;

    .templateName {
      flex: 1;
    }

    &:hover .templateName {
      opacity: 0.35;
    }

    &Selected {
      color: #4977e9;
      font-weight: 600;
    }
  }
}

.btn {
  color: #4977e9 !important;
  border: 1px solid #f0f0f0 !important;

  &:hover {
    color: #2f54eb !important;
  }
}
