.requestIlsPage {
  .wrapperIlsButtons {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 10px;
  }

  .ils-action-buttons {
    display: flex;
    gap: 5px;

    .ant-btn {
      padding: 0;
    }
  }
}
