* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  color: inherit;
  background: none;
  text-decoration: none;
  border: none;
}

.hideSpan {
  display: none !important;
}

.big-font {
  font-size: 50px;
}

.align-r {
  text-align: end;
}

body {
  width: 100%;
  /*min-height: 100vh;
  overflow-y: hidden;*/
}

html > body > iframe {
  display: none !important;
}

#root {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ant-col-0 {
  display: inherit !important;
}

.v-align-middle {
  vertical-align: middle !important;
}

.tariffTable {
  width: 100%;
  border-collapse: collapse;
  border: none;
  margin-top: 9px;
  table-layout: fixed;
}

.tariffTable th {
  color: #777b87;
  text-align: left;
  height: 67px;
  font-weight: normal;
  border: 1px solid #dedddd;
  padding-left: 3px;
  padding-right: 3px;
}

.blue-gray-button {
  height: 44px;
  border: white;
  margin-top: 16px;
  margin-bottom: 16px;
  background: #f8f9fb;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4977e9;
}

.tariffTable tr th:first-child {
  border-left: 0;
  padding-left: 20px;
}

.tariffTable tr th:last-child {
  border-right: 0;
}

.tariffTable td {
  text-align: left;
  height: 50px;
  padding-left: 3px;
  padding-right: 3px;
  border: 1px solid #dedddd;
}

.tariffTable tr td:first-child {
  border-left: 0;
  padding-left: 20px;
}

.tariffTable tr td:last-child {
  border-right: 0;
}

.tariffTable tbody tr:nth-of-type(even) {
  background: #f5f5f5;
}

.money-value {
  text-align: right !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  padding: 0;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  text-align: center !important;
}

.dx-datagrid {
  background-color: transparent !important;
}

.dx-submenu .dx-datagrid {
  background: #fff !important;
}

.leaflet-tooltip.title-tooltip {
  background-color: transparent;
  border: transparent;
  box-shadow: none;
}

.scrolledContainer {
  padding: 0px 0px 0px 0px;
  background: #fff;
}

.ant-input-number {
  width: 100%;
}

.pg-viewer-wrapper {
  overflow-y: auto !important;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
  padding: 0 11px;
}

.ant-popover-inner {
  border-radius: 6px;
}

.zebra > .ant-row:nth-of-type(odd) {
  background: #f5f5f5 !important;
}

.zebra > .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.zebra > .ant-row > .ant-col {
  padding-left: 6px;
}

.zebra > .ant-col {
  background: #f0f0ff !important;
  text-align: center;
}

.table {
  max-width: 89vw;
}

.table > th {
  padding-left: 4px;
  border: #bac6d4 solid 2px;
}

.table > td {
  padding-left: 4px;
  border: #bac6d4 solid 2px;
}

.table > tr:nth-of-type(odd) {
  background: #f5f5f5 !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle !important;
  padding: 0.25rem;
}

.dx-datagrid-content .dx-datagrid-table .ant-col {
  padding: 0;
}

.dx-datagrid-content .dx-datagrid-table form {
  align-items: center;
}

.dx-datagrid-content .ant-form-large .ant-form-item-control-input {
  min-height: unset;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #77bf39;
}

.ant-tree-title {
  width: 100%;
}

.ant-tree-treenode {
  width: 100%;
}

.ant-tree-node-content-wrapper {
  width: 100%;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  font-family: 'OpenSans-Regular';
  font-size: 12px;
}

.background-to-white {
  background-color: #ffffff;
  -webkit-transition: background-color 2000ms linear;
  -ms-transition: background-color 2000ms linear;
  transition: background-color 2000ms linear;
}

.alarm-background {
  transition: background-color 1s;
  background-color: #ffbdd0;
}

.bw-image {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-top: 23px;
  /*margin-bottom: 23px;*/
}

h1 {
  /* Hender/H1/Bold */
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  /* identical to box height, or 138% */
  display: flex;
  align-items: center;
  /* Dark / Black */
  color: #000000;
  margin: 0;
}

/* .equipmentTable .dx-datagrid-content .dx-datagrid-table .dx-row>td {
  padding: 0.25rem 0;
}

.equipmentTable .dx-datagrid-content .dx-datagrid-table .dx-row>td>div {
  padding: 0 0.25rem;
} */

.equipmentTable .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody tr td div {
  border-top: 1px solid #dedede;
  padding: 3px 0;
}
.equipmentTable .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody tr td {
  line-height: 12px;
  text-align: center !important;
}

.equipmentTable .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody tr td div:first-child {
  border-top: none;
}

.alignCenter {
  text-align: center !important;
}

.ils-wagon-count {
  margin-left: 10px;
  color: #4977e9;
  background-color: #f1f2f6;
  padding: 0 10px;
}

.leaflet-control-attribution a {
  display: none !important;
}
