@tariffResultHeaderFontSize: 16px;
@tariffResultBodyFontSize: 14px;

* {
  --header-height: 4rem;
  --card-padding: 3rem;
  --padding: 8.2rem;
  --dx-padding: 12rem;
}

//Popover
.ant-popover {
  font-size: 12px;
  line-height: 16px;
}

.ant-popover-inner-content {
  padding: 8px 12px 8px 12px !important;
  width: 100%;
}

//datepicker
.ant-picker-content th {
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  color: #808c9a;
}

.ant-picker-content td {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.ant-picker-month-btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.ant-picker-year-btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.ant-picker-cell .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 12px;
}

//select
.ant-select {
  width: 100%;
}

.ant-page-header-ghost {
  background-color: white !important;
  -webkit-box-shadow: 10px 2px 12px -6px rgb(23, 21, 21, 0.61);
  -moz-box-shadow: 10px 2px 12px -6px rgb(23, 21, 21, 0.61);
  box-shadow: 10px 2px 12px -6px rgb(23, 21, 21, 0.61);

  padding: 0px 10px 0px 10px;
  //overflow-x: hidden !important;
  z-index: 1;
}

.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
  height: var(--header-height);
}

.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: auto 0;
  padding: 0;
  overflow: unset;
  flex: 1;
}

.ant-page-header-heading-sub-title {
  margin-left: auto;
}

.ant-page-header-heading-sub-title > * {
  //margin: 0px 1vw 0px 1vw;
  margin: 0;
  margin-left: 0;
}

.ant-page-header-heading-extra {
  display: flex;
  align-content: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0 0.1rem 0 0;
}

.ant-page-header-heading-extra > * {
  margin-left: 0;
  //padding: 0 0.6vw !important;
}

.ant-layout {
  height: 100vh;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 24px !important;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  font-size: 17px;
  padding-left: 4px;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  margin-top: 12px;
}

.ant-menu-item {
  font-size: 18px !important;
  //padding-left: 100px !important;
}

.ant-layout-content > .content {
  //margin-top: 35px !important;
  background: #eef3f7;
}

.title {
  font-weight: bold;
  padding: 37px 47px 0px 58px !important;
  font-size: 26px;
}

.ant-layout-content > div {
  //padding: 0px 0px 0px 0px;
  //background: #eef3f7;
  color: #333333;
  overflow-x: hidden !important;
  //align-content: center;
  align-items: center;
}

.ant-page-header-heading-title {
  margin-right: 0;
  font-family: OpenSans-bold;
  font-size: 24px;
  font-weight: normal;
}

.ant-row {
  background: inherit !important;
}

.ant-list-items {
  background: #ffffff !important;
}

.ant-divider {
  background: #ffffff !important;
}

.ant-menu-submenu-title {
  padding-left: 77px;
}

.ant-menu-item {
  padding-left: 77px;
}

.ant-divider-horizontal {
  background: #ffffff !important;
}

.ant-divider-with-text {
  background: #ffffff !important;
}

.ant-input-number {
  width: 100%;
}

.ant-badge-multiple-words {
  padding: 0;
}

.scrollbar {
  float: left;
  width: 100%;
  height: calc(100vh - 108px);
  background: #515769;
  overflow-y: auto !important;
  -webkit-box-shadow: 5px 0px 5px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 0px 5px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 0px 5px -5px rgba(0, 0, 0, 0.75);
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #515769;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #515769;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.5, #4977e9), color-stop(0.5, #4977aa));
}

.chapter {
  padding-left: 0.5rem;
  font-family: 'OpenSans-Semibold';
  font-size: 20px;
}

.btn-close {
  padding: 0.5em;
  float: right;
}

.btn-close > span {
  font-size: 31px !important;
}

.btn-arrow {
  margin: 0 0.2rem;
}

.btn-close > span {
  font-size: 23px !important;
  vertical-align: middle;
}

.sources {
  border: 1px solid #d9d9d9;
}

.sources > .ant-select-selector,
.ant-select-item-option-content {
  padding: 0px;
}

.map-drawer-title-text {
  height: 28px;
  font-family: 'OpenSans-Semibold';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  flex: none;
  flex-grow: 1;
}

.map-drawer-title > .anticon {
  font-size: 30px;
  padding-right: 7px;
}

.map-drawer {
  width: 420px;
}

.map-drawer > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-header > .ant-drawer-title {
  display: table-cell;
  vertical-align: middle;
  padding-left: 22px;
}

.map-drawer > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-header {
  display: table;
  padding: 0;
  height: 80px;
  background: #4977e9;
}

.ant-drawer {
  z-index: 1001;
}

.ant-drawer-title {
  height: 28px;
  font-family: 'OpenSans-Semibold';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.map-table-station {
  width: 100%;
}

.map-table-station img {
  object-fit: cover;
  height: 64px;
  border-radius: 50%;
  aspect-ratio: auto 64 / 64;
  width: 64px;
  margin: 10px;
}

.map-table-station td {
  padding: 8px 0px;
}

.map-table-station {
  text-align: center;
  font-family: OpenSans-Semibold;
  font-size: 18px;
}

.map-station-value {
  text-align: left;
  font-family: OpenSans-Semibold;
  font-size: 15px;
}

.map-station-label {
  text-align: left;
  font-family: OpenSans-Regular;
  font-size: 15px;
}

.map-button {
  margin: 10px 0px;
  height: 48px;
  width: 226px;
  font-size: 17px;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 24px / 2);
  text-overflow: clip;
}

.etranButtonsGroup > div > .ant-btn {
  width: 124px;
  margin: 0 5px 5px 0;
  background-color: #7da5f0;
  color: #ffffff;
  border-radius: 0px;
}

.etranButtonsGroup > .ant-btn {
  margin: 0 5px 5px 0;
  background-color: #7da5f0;
  color: #ffffff;
  border-radius: 0px;
}

.etranButtonsGroup > .ant-btn[disabled] {
  margin: 0 5px 5px 0;
  color: #b4cbf5;
  border-radius: 0px;
  background-color: #7da5f0;
  text-shadow: none;
  box-shadow: none;
}

.etranButtonsGroup > .ant-btn.ant-btn-icon-only {
  width: 32px !important;
  vertical-align: top;
}

.etranCaption {
  color: black;
  padding: 0;
  font-weight: bold;
  font-size: 14px;
}

.etranFormItem > .ant-col {
  text-align: left;
}

.etranFormItem > .ant-form-item-label {
  height: unset;
}

.etranFormItem > .ant-form-item-label > label {
  height: unset;
  white-space: pre-wrap;
  padding-left: 5px;
  font-size: 14px;
}

.comboEditOther {
  display: inline-grid;
  background-color: #e3e2ff;
  border: solid 1px #cbcbd4;
  border-radius: 0px;
  color: black;
  font-size: 12px;
  padding: 0px;
  width: 100%;
  min-width: 35px;
  height: 24px;
}

.etranInputSearch input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: black;
  font-size: 12px;
  font-family: 'OpenSans-Regular' !important;
  line-height: unset;
}

.etranInputSearch input {
  border-radius: 0rem;
  padding: 0 0.5rem;
  width: 100%;
  display: inline-grid;
  background-color: #ffffde;
  border: solid 0.0625rem #cbcbd4;
  border-radius: 0rem;
  height: 1.5rem;
  font-feature-settings: 'tnum', 'tnum', 'tnum';
  font-variant: tabular-nums;
  align-items: end;
}

.ant-input.ant-input-sm.comboEditOther {
  padding: 0 0.5rem;
}

.textSelect {
  background-color: #ffffde;
  border: solid 1px #cbcbd4;
  border-radius: 0px;
  width: 100%;
  height: 22px;
}

.textSelect > .ant-input-number-input-wrap > .ant-input-number-input {
  padding: 0 0.5rem;
}

.comboEditSearchOther {
  display: inline-flex;
  justify-content: space-between;
  padding: 0 0px;
  min-width: 40px;
  width: 100%;
  height: 24px;
  align-items: center;
}

.comboEditSearchOther > .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 0px;
}

.comboEditSearchOther > .ant-select-dropdown {
  border: none;
  border-radius: 0px;
}

.comboEditSearchOther:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #e3e2ff;
  border-radius: 0px;
  transition: none;
}

.comboEditSearchOther .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: normal;
  transition: all 0.3s;
}

/*.comboEditSearchOther > .ant-select-single > .ant-select-selector > .ant-select-selection-item {
  padding-left: 4px;
}*/

.comboEditSearchOther > .ant-select-selector > span {
  align-self: center;
}

/*.comboEditSearchOther > .ant-select-selector > .ant-select-selection-item {
  padding-left: 4px;
}*/

.comboEditSearchOther.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  //width: 100%;
  height: 24px;
  padding: 0 0.5rem;
}

.comboEditSearchOther > .ant-row.ant-form-item {
  height: 24px;
  width: 100%;
}

.comboEditSearchOther > .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  height: 24px;
  width: 100%;
}

.comboEditSearchOther > .ant-row.ant-form-item > .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  height: 24px;
  width: 100%;
}

.comboEditSearchOther > .ant-row.ant-form-item > .ant-form-item-control > .ant-form-item-control-input {
  height: 24px;
  min-height: 24px;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  height: 24px;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row {
  height: 24px;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col {
  height: 24px;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col
  > .ant-col {
  height: 24px;
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col
  > .ant-col
  > .ant-row.ant-form-item.etran-form-item.etran-extra {
  height: 1.25rem;
  margin-bottom: 0 !important;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col
  > .ant-col
  > .ant-row.ant-form-item.etran-form-item.etran-extra
  > .ant-col.ant-form-item-control {
  height: 1.25rem;
  padding-top: 0;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col
  > .ant-col
  > .ant-row.ant-form-item.etran-form-item.etran-extra
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input {
  height: 1.25rem;
  margin-right: 0px;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col
  > .ant-col
  > .ant-row.ant-form-item.etran-form-item.etran-extra
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  height: 1.25rem;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col
  > .ant-col
  > .ant-row.ant-form-item.etran-form-item.etran-extra
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-select {
  height: 1.25rem;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col
  > .ant-col
  > .ant-row.ant-form-item.etran-form-item.etran-extra
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-select
  > .ant-select-selector {
  height: 1.25rem;
  padding: 0 0.25rem;
  border: solid 0.063rem #cbcbd4;
  background-color: #e3e2ff !important;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col
  > .ant-col
  > .ant-row.ant-form-item.etran-form-item.etran-extra
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-select
  > .ant-select-selector
  > ant-select-selection-search {
  height: 1.25rem;
  padding: 0;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col
  > .ant-col
  > .ant-row.ant-form-item.etran-form-item.etran-extra
  > .ant-col.ant-form-item-control
  > .ant-form-item-extra {
  height: 1.25rem !important;
  min-height: 1.25rem;
  order: 1 !important;
  padding-top: 0px;
  margin-left: 0.3rem !important;
  width: unset !important;
  min-width: 6.25rem;
}

.comboEditSearchOther
  > .ant-row.ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-row
  > .ant-col
  > .ant-col
  > .ant-row.ant-form-item.etran-form-item.etran-extra
  > .ant-col.ant-form-item-control
  > .ant-form-item-extra
  > .ant-input.ant-input-sm {
  display: inline-grid;
  background-color: #ffffde;
  border: solid 0.063rem #cbcbd4;
  border-radius: 0;
  color: black;
  padding: 0 0.125rem;
  width: 100%;
  min-width: 6.25rem;
  height: 1.25rem;
  text-align: left !important;
}

.invBlankType
  > .ant-row.ant-form-item
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  padding-left: 5px;
}

.invCountry {
  position: relative;
  min-height: 1px;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}

.invCountry > .ant-col.ant-form-item-control {
  display: inline;
  position: relative;
  max-width: 100%;
  min-height: 1px;
}

.invCountry > .ant-col.ant-form-item-control > .ant-form-item-control-input {
  position: relative;
  min-height: 1px;
  display: inline-block;
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.invCountry > .ant-col.ant-form-item-control > .ant-form-item-extra {
  position: relative;
  min-height: 1px;
  display: inline-block;
  padding-top: 0;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  color: black;
}

.ant-form-item-extra {
  padding-top: 0;
}

.invCountryExtra {
  background-color: #ffffde;
  border: solid 1px #cbcbd4;
  border-radius: 0px;
  padding: 0 0.5rem;
  min-width: 100px;
  height: 24px;
  color: black;
}

.invPayer > .ant-col > .ant-row > .ant-col.ant-form-item-control > .ant-form-item-extra {
  display: inline-flex;
}

.invPayer > .ant-col > .ant-row > .ant-col.ant-form-item-control > .ant-form-item-extra::before {
  content: 'Код плательщика';
  padding-right: 5px;
}

.invPayer > .ant-col > .ant-row > .ant-col.ant-form-item-control > .ant-form-item-extra > .ant-input.ant-input-sm {
  width: 76px !important;
  min-width: 76px !important;
}

.invPayer
  > .ant-col
  > .ant-row
  > .ant-col
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-select
  > .ant-select-selector
  > .ant-select-selection-item {
  padding: 0 0.25rem;
}

.etranDatePickerMSK {
  background-color: #e3e2ff;
}

.etranDatePickerLocal {
  background-color: #ffffde;
}

.etranFormItemInine {
  display: block !important;
  flex: 0 0 83.33333333% !important;
  max-width: 83.33333333% !important;
}

.ant-row.ant-form-item.etranFormItemInine
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  display: inline-flex;
  align-items: center;
}

.ant-row.ant-form-item.etranFormItemInine
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-col {
  padding-right: 0.313rem;
}

@import 'modal.less';

.etran-modal {
  max-height: 100vh;
  width: 100vw !important;
}

.etran-modal * {
  border-radius: 0px !important;
}

.etran-modal > .ant-modal-content > .ant-modal-close {
  width: 3.5rem;
  height: 3.5rem;
}

.etran-modal > .ant-modal-content > .ant-modal-body > div {
  overflow: hidden auto;
  max-height: calc(100vh - 265px);
}

.etran-modal > .ant-modal-content > .ant-modal-close > .ant-modal-close-x {
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
}

.etran-modal > .ant-modal-content > .ant-modal-close > .ant-modal-close-x > .ant-modal-close-icon {
  color: white;
}

.etran-modal > .ant-modal-content > .ant-modal-header {
  background-color: #7da5f0;
}

#etran-editor
  > .ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > div
  > .ant-select-selector {
  background-color: #e3e2ff;
}

.etran-form-route {
  min-width: '100%';
}

.etran-form-route .ant-form-item-control-input-content {
  .ant-col {
    padding: 0 0.313rem 0 0;
  }

  display: flex;
  flex: auto;
  width: 100%;
  justify-content: flex-start;
}

.etran-form-route > ant-form-item-control-input > etran-form-route {
  width: 100%;
}

.etran-form-label-wrapper {
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  .ant-checkbox-wrapper {
    display: table;
    vertical-align: middle;
    padding: 0 0.25rem;
  }
}

#etran-editor > .ant-form-item > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > textarea {
  background-color: #ffffde;
}

#etran-editor
  > .ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-input-number.ant-input-number-sm {
  background-color: #ffffde;
}

#etran-editor > .ant-form-item > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > input {
  background-color: #ffffde;
}

#etran-editor
  > .ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-checkbox-wrapper
  > .ant-checkbox
  > .ant-checkbox-inner {
  background-color: #ffffde;
}

#etran-editor
  > .ant-form-item
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-col
  > .etran-extra
  > .ant-form-item-control
  > .ant-form-item-extra
  > input {
  border: solid 0.063rem #cbcbd4;
}

.etran-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  text-align: center;
}

#etran-editor > .ant-form-item > .ant-form-item-label {
  height: unset;
  text-align: left;
}

#etran-editor > .ant-form-item > .ant-form-item-label > label {
  text-align: start;
  color: black !important;
  font-size: 0.75rem;
  vertical-align: middle;
}

#etran-editor > .ant-form-item > .ant-form-item-label > label::after {
  content: unset;
}

#etran-editor .ant-row {
  min-height: 1.75rem;
  padding: 0.1rem 0;
}

#etran-editor .ant-row.ant-form-item {
  min-height: 1.75rem;
  padding: 0 !important;
}

.node {
  padding: 0;
}

.ant-modal-body > div > #etran-editor .ant-row.ant-form-item {
  min-height: 1.75rem;
  padding: 0.1rem 0 !important;
}

#etran-editor_invPayer.ant-row {
  padding: 0 !important;
}

#etran-editor .ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  border-color: #4977e9;
  border-right-width: 1px !important;
  outline: 0;
  border-radius: 0px;
  //box-shadow: 0 0 0 2px rgb(73 119 11.65%);
}

.etranContent > .ant-row > .ant-col > .ant-row.ant-form-item {
}

#etran-editor > div > .ant-form-item-control > div > div > div > div > div > .ant-form-item-extra {
  padding-top: 0;
}

.etranModalScrolledContainer {
  max-height: calc(100vh - 18.75rem);
  overflow-x: hidden;
}

#etran-editor
  > .ant-form-item
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content
  > .ant-checkbox-wrapper
  > .ant-checkbox-checked
  .ant-checkbox-inner::after {
  border: 0.125rem solid #3256c2;
  border-top: 0;
  border-left: 0;
}

.etran-extra .ant-col.ant-form-item-control {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.etran-extra .ant-col.ant-form-item-control .ant-form-item-control-input {
  width: 80%;
}

.etran-extra .ant-col.ant-form-item-control .ant-form-item-extra {
  width: 20%;
  padding: 0 0 0 0.313rem;
}

.etran-extra .ant-form-item-extra .ant-input.ant-input-sm {
  display: inline-grid;
  background-color: #ffffde;
  color: black;
  border: solid 0.0625rem #cbcbd4;
  border-radius: 0rem;
  padding: 0 0.5rem;
  width: 100%;
  min-width: 2.5rem;
  height: 1.5rem;
}

.etranStatusViewerButton {
  vertical-align: middle;
}

.number {
  color: #707070;
  font-size: 1.5rem;
  min-height: 3.5rem;
}

.number > span {
  vertical-align: -webkit-baseline-middle;
}

.numberSelect {
  cursor: pointer;
  font-size: 36px;
  color: #707070;
}

.number:not(.empty)::before {
  content: '●';
  font-size: 24px;
  font-bold: bold;
  vertical-align: -webkit-baseline-middle;
  color: #78b6d9;
}

.number:not(.loaded)::before {
  content: '●';
  font-size: 24px;
  font-bold: bold;
  vertical-align: -webkit-baseline-middle;
  color: #60a69f;
}

.numberSelect:not(.empty)::before {
  content: '●';
  font-size: 36px;
  font-bold: bold;
  color: #78b6d9;
}

.numberSelect:not(.loaded)::before {
  content: '●';
  font-size: 36px;
  font-bold: bold;
  color: #60a69f;
}

.numberInfo {
  cursor: pointer;
  color: #bfbfbf;
}

.like-dxc-title {
  white-space: pre;
  font-size: 19px;
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', 'Verdana', 'sans-serif';
  font-bold: bold;
  font-weight: bolder;
  color: #232323;
  cursor: default;
}

.wagon {
  display: grid;
}

.wagon > span > svg {
  width: 95%;
  height: 95%;
}

.wagonPerformance > span {
  color: #64c05c;
}

.wagonSecuring > span {
  color: #5d71dc;
}

.wagonPerformance > span > svg > path {
  stroke: #64c05c;
}

.wagonSecuring > span > svg > path {
  stroke: #5d71dc;
}

.numberWagon {
  cursor: pointer;
  font-size: 36px;
  color: #707070;
}

.wagonFlex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.loadWagon > svg > path {
  stroke-width: 40px;
}

.numberSecuring {
  font-size: 36px;
}

.numberPerformance {
  font-size: 36px;
}

.numberSecuring {
  font-size: 36px;
  font-bold: bold;
  color: #5d71dc;
}

.numberPerformance {
  font-size: 36px;
  font-bold: bold;
  color: #64c05c;
}

.has-error > .ant-select-selector {
  border: 1px solid #ff3939 !important;
}

.has-error > .ant-select-selector > .ant-select-selection-placeholder {
  align-self: center;
}

.padding-bottom-3 > .ant-col {
  padding-bottom: 3px;
  padding-right: 3px;
}

.ecp-ant-list-item-meta {
  width: fit-content;
}

.ecp-ant-list-item-meta > .ant-list-item-meta-content {
  width: fit-content;
}

.ecp-ant-list-item-meta > .ant-list-item-meta-content > .ant-list-item-meta-description {
  width: fit-content;
}

.ecp-ant-list-item-meta > .ant-list-item-meta-avatar {
  padding: 5px;
}

.ecpModal {
  font-size: 17px;
  width: fit-content;
}

.ecp-ant-list-item {
  padding: 5px 0 !important;
}

.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline > .divider {
  padding: 0 24px;
}

.notifications > div > div > div > div > div > div {
  max-width: 100% !important;
}

.support {
  text-align: center;
  padding: 0 0 !important;
  margin: 0 0 !important;
}

.support > .ant-btn {
  padding: 0 0 !important;
  margin: 0 0 !important;
}

.support > .ant-btn > .anticon {
  display: list-item;
  padding: calc(50% - 34px / 2) 0 !important;
  margin: 0 0 !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.send-editor-drawer > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-body {
  flex-grow: 1;
  padding: 0;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.send-editor-drawer .anticon-close > svg {
  fill: black;
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  border-color: #ff4d4f !important;
}

.send-editor-drawer .ant-drawer-close {
  --scroll-bar: 0px;
  position: absolute;
  right: 0;
  z-index: 10;
  display: block;
  padding: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 36px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}

aside {
  //z-index: 1001;
}

.ant-select-arrow {
  color: black;
  font-size: 10px;
  font-weight: bold;
}

.ant-card-bordered {
  outline: 1px solid #dedede;
  border: none;
  padding: 0 !important;
}

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 4.5rem;
  left: 0.5rem;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: unset;
  text-align: unset;
  background: #515769;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}

.leaflet-button-map {
  position: absolute;
  top: 1rem;
  left: 3rem;
  z-index: 1000;
}

.leaflet-input-map {
  position: absolute;
  top: 1rem;
  left: 5.2rem;
  z-index: 1000;
  width: 400px;
  background: white;
}

.leaflet-left {
  left: -0.1rem !important;
}

.leaflet-top {
  top: 3rem !important;
}

.leaflet-company-selector {
  position: absolute;
  top: 1rem;
  left: 30.5rem;
  z-index: 1000;
  width: 10rem;
  background: white;
}

.tariffTableMobile tbody {
  width: 100%;
}

.tariffTableMobile td {
  width: 100%;
  text-align: unset;
  height: unset;
  padding: 0;
  border: unset;
}

.tariffTableMobile td {
  width: min-content;
  text-align: unset;
  height: unset;
  padding: 0;
  border: unset;
}

.tariffTableMobile tr th:first-child {
  border-left: 0;
  padding-left: 20px;
}

.tariffTableMobile tr th:last-child {
  border-right: 0;
}

.tariffTableMobile tbody tr:nth-of-type(even) {
  background: #f5f5f5;
}

.tariffTableMobile tr {
  width: 100%;
  text-align: left;
  height: 1.6rem;
  padding: 0 1.2rem;
  border: 1px solid #dedddd;
  display: inline-block;
}

.tariffTableMobile tr .ant-btn-sm {
  margin: 0;
  padding: 0;
  height: 100%;
}

.searchMessage {
  width: 12rem;
  height: 1.9rem;
}

.searchMessage input {
  height: 1.9rem;
}

.messageUsers {
  cursor: pointer;
  font-size: 1.2rem;
  padding-left: 0.5rem;
}

.messageUsers span {
  vertical-align: middle;
}

.usersRoleAccess > div > div > .ant-checkbox-wrapper > span {
  white-space: normal;
}

.usersRoleAccess .ant-checkbox.ant-checkbox-checked {
  white-space: nowrap;
}

.timeLink {
  padding: 0.3rem;
}

.ant-card-mobile {
  //padding: 8px !important;
}

.ant-divider-inner-text {
  display: inline-block;
  padding: 0 0;
}

.ant-card-mobile > .ant-card {
  //min-width: 95vw !important;
}

.ant-card-padding {
  padding: 16px;
}

.tariffTable tr th:first-child {
  border-left: 0;
  padding-left: 0;
}

.ant-tabs-tab-btn {
  color: #808c9a;
}

.login-page > .ant-card-head > .ant-card-head-wrapper {
  text-align: center;
}

.login-page > .ant-card-head .ant-card-head-title {
  padding: 15px 0;
}

.scrolled-with-header {
  height: calc(100vh - var(--header-height));
}

.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 0.25rem;
}

.ant-radio-wrapper {
  display: table;
  vertical-align: middle;
  padding: 0.25rem;
}

.ant-modal-wrap {
  z-index: 1001;
}

.ant-page-header {
  padding-left: 32px;
  padding-right: 16px;
}

.ant-page-header.ant-page-header-ghost {
  height: var(--header-height);
}

.ant-layout-content {
  flex: unset;
  min-height: 0;
  display: grid;
  height: inherit;
}

.ant-list-item-meta-title {
  margin-top: 0;
}

.message-input {
  width: 100%;
  background-color: white;
  padding: 20px;
  height: fit-content;
  align-self: self-end;
}

.filtertag.ant-tag {
  border-radius: 4px;
  font-size: 9px;
  padding: 0 3px;
  line-height: 13px;
}

.background-to-transparent {
  transition: background-color 1s;
  background-color: transparent;
}

.dispatcher-card {
  background-color: #f0f2f5;
}

.dispatcher-card.ant-card-hoverable {
  transition: background-color 0.3s;
}

.dispatcher-card.ant-card-hoverable:hover {
  background-color: white;
}

.dispatcher-card .ant-btn-text {
  background: transparent;
  border-color: transparent;
}

.dispatcher-field {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 12px;
  //padding-left: 1rem !important;
  //padding-right: 1rem !important;
  margin: 0;
}

.dispatcher-search-wrapper {
  padding-left: 8px;
  padding-right: 8px;
  height: 4.25rem;
}

.dispatcher-search {
  padding: 1rem 0 1rem 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.dispatcher-search button {
  border-left: none;
}

.dispatcher-fields {
  color: #808c9a;
}

.dispatcher-fields > div:nth-of-type(odd) {
  background: #f8f9fb !important;
}

.dragableList td {
  color: #000000a6 !important;
}

.dragableList.dx-widget {
  font-family: 'OpenSans-Regular' !important;
  font-size: 14px !important;
  font-variant: tabular-nums !important;
  font-feature-settings: 'tnum', 'tnum' !important;
}

.dragableList > td {
  padding: 3px;
}

.dragableList .dx-row:nth-of-type(odd) {
  background: #f8f9fb !important;
}

.dragableList .dx-row {
  border-top: unset !important;
  border-bottom: unset !important;
  padding: 0;
}

.dispacher-form-wrapper {
}

.dispacher-form-wrapper .ant-row.ant-form-item {
  width: 100px;
}

.dragableList .dx-datagrid.dx-gridbase-container {
  border-radius: 12px;
}

.dispacher-drawer .ant-drawer-body {
  padding: 0px;
  background: #e5e5e5;
}

.dragableList td {
  padding: 0 !important;
}

.dispacher-drawer .ant-radio-button-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.dispatcher-radio-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 43px;
}

.dispatcher-form .ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: transparent;
  transition: background-color 0.3s;
  content: '';
}

.dispatcher-form .ant-form-item-required {
  padding: 0 0 1rem 0.5rem !important;
  font-family: 'OpenSans-Semibold';
  font-size: 20px;
}

.dispatcher-chapter {
  padding-left: 0.5rem;
  font-family: 'OpenSans-Semibold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.dispatcher-card .ant-divider {
  border-top: 1px solid #808c9a;
  margin: 1rem 0;
  color: #808c9a;
  opacity: 0.2;
}

.dispatcher-card .chapter {
  font-family: OpenSans-Semibold;
  font-size: 16px;
  color: black;
  padding: 0;
}

.dispatcher-card .ant-card-body {
  height: 90%;
  padding: 0 1rem 1rem 1rem;
}

.dispatcher-card .ant-card-head {
  border-bottom: none;
  padding: 0px 1rem;
}

.dispatcher-icon {
  font-size: 16px !important;
}

.dispatcher-icon-red {
  color: #ff0000;
}

.dispatcher-icon-blue {
  color: #4977e9;
}

.dispatcher-icon-gray {
  color: #808c9a;
}

.dispatcher-search-input {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-left: 2rem;
}

.dispatcher-filtertag-wrapper {
  min-height: 2.8rem;
}

.dispatcher-filtertag-wrapper > .ant-tag {
  height: 16px;
  background: #4977e9;
  border-radius: 4px;
}

.dispatcher-filtertag-wrapper > .ant-tag > a {
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  color: #ffffff;
}

.dispathcher-date {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}

.verticalTable > div {
  padding: 1rem;
}

.tariffCalculationTable {
  display: grid;
}

.tariffCalculationTable > div > div {
  font-weight: 400;
  font-size: @tariffResultBodyFontSize;
  display: grid;
  color: #000000;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-content: center;
  align-content: center;
  height: 2rem;
  padding: 0.5rem;
}

.tariffCalculationTable > div:first-child > div {
  font-weight: 600;
  font-size: @tariffResultHeaderFontSize;
  color: #808c9a;
}

.tariffCalculationTable > div > div:nth-of-type(even) {
  background: #f8f9fb;
}

.tariffCalculationTable > div {
  border: 1px solid #f0f0f0;
  border-width: 1px 1px 1px 0px;
}

.tariffCalculationTable > div:first-child {
  border-radius: 5px 0rem 0rem 5px;
  border-width: 1px 1px 1px 1px;
}

.tariffCalculationTable > div:first-child > div > button {
  direction: rtl;
}

.tariffCalculationTable > div > div {
  display: grid;
  align-content: center;
  justify-content: center;
  white-space: nowrap;
}

.tariffCalculationTable > div:first-child > div {
  font-weight: 600;
  font-size: @tariffResultHeaderFontSize;
  //line-height: 0px;
  //width: 163px;
  display: grid;
  justify-content: space-between;
  align-content: center;
}

.tariffCalculationTable > div:last-child {
  border-radius: 0rem 5px 5px 0rem;
}

.tariffCalculationTable .ant-btn {
  padding: 0;
}

.tariffCalculationTable .ant-btn-sm {
  margin: 0;
}

.tariffCalculationTable .ant-btn-sm {
  font-size: @tariffResultHeaderFontSize;
  padding: 0;
}

.tariffCalculationTable .ant-btn {
  border: unset;
  height: 2rem;
}

.total {
  display: grid;
  grid-gap: 1rem;
}

.totalTable > div > div {
  height: 2rem;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  white-space: nowrap;
}

.totalTable > div > div:nth-of-type(odd) {
  background: #f8f9fb;
}

.totalTable > div > div:nth-child(2) {
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  //border-radius: 5px 5px 0rem 0rem;
}

.totalTable > div > div:last-child {
  border-bottom: 1px solid #f0f0f0;
  //border-radius: 0rem 0rem 5px 5px;
}

.total > div {
  display: grid;
}

.total > div > div > div {
  padding: 0.5rem;
}

.totalTable > div:first-child > div {
  font-weight: 600;
  font-size: @tariffResultHeaderFontSize;
  color: #808c9a;
  display: grid;
  justify-content: space-between;
  align-content: center;
}

.totalTable > div:first-child > div:first-child {
  min-width: 300px;
}

.totalTable > div:last-child > div {
  font-weight: 600;
  font-size: @tariffResultBodyFontSize;
  color: #000000;
  display: grid;
  justify-content: space-between;
  align-content: center;
}

.totalTable > div:first-child > div {
  font-weight: 600;
  font-size: @tariffResultHeaderFontSize;
  color: #808c9a;
  display: grid;
  justify-content: space-between;
  align-content: center;
}

.totalTable > div:last-child > div {
  font-weight: 600;
  font-size: @tariffResultBodyFontSize;
  display: grid;
  justify-content: end;
  align-content: center;
  border-left: 0;
}

.totalTable > div:first-child > div:nth-child(2) {
  border-radius: 5px 0 0 0;
}

.totalTable > div:first-child > div:last-child {
  border-radius: 0 0 0 5px;
}

.totalTable > div:last-child > div:nth-child(2) {
  border-radius: 0 5px 0 0;
}

.totalTable > div:last-child > div:last-child {
  border-radius: 0 0 5px 0;
}

.totalTable > div > div:first-child {
  background: transparent;
  border: 0;
  height: 1.375rem;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
}

.totalTable .totalNumber {
  grid-template-columns: repeat(2, auto);
  grid-gap: 0.2rem;
}

.weight {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  width: fit-content;
  overflow: hidden;
}

.weight th,
.weight td {
  border-right: 1px solid #f0f0f0;
}

.weight th {
  font-weight: 600;
  font-size: 16px;
  color: #808c9a;
  justify-content: space-between;
  align-content: center;
  height: 2rem;
  padding: 0.5rem;
}

.weight td {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  justify-content: space-between;
  align-content: center;
  height: 2rem;
  padding: 0.5rem;
}

.weight tr:nth-of-type(odd) {
  background: #f8f9fb;
}

.weight tr > td:last-child {
  border-right: none;
}

.weight th:last-child {
  border-right: none;
}

.result-card .ant-card {
  min-width: unset !important;
}

.totalNumberFgk {
  grid-template-columns: repeat(2, auto);
  grid-gap: 0.2rem;
}

.rc-virtual-list-holder-inner > div {
  border-bottom: 1px solid #f0f0f0;
}

.rc-virtual-list-holder-inner > div:last-child {
  border-bottom: none;
}

.tariffCalculationTable > div > div > .ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0;
}

.header-tabs {
}

.result-card {
  min-width: 660px;
}

.total {
  grid-template-columns: repeat(auto-fit, 379px);
}

.tariffCalculationTable.parameters {
  min-width: 597px;
  max-width: fit-content;
}

.tariffCalculationTable.parameters > div {
  grid-row: 1;
}

.tariffCalculationTable.parameters > div > div {
  justify-content: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.way {
  display: grid;
  grid-template-columns: repeat(8, auto);
  gap: 1px;
  padding: 1px;
  background: #f0f0f0;
}

.way > div {
  font-family: OpenSans-Regular, sans-serif;
  background: white;
  padding: 0.5rem;
  white-space: pre;
}

.way > div:nth-child(-n + 8) {
  font-weight: 600;
  color: #808c9a;
}

.tgnl > .ant-form-item-label {
  font-size: 12px !important;
  padding-left: 5px;
  padding-top: 5px;
}

.tgnl > .ant-form-item-control {
  padding-top: 5px;
  width: 1%;
}

.etran-address {
  margin-bottom: 0px !important;
  padding-bottom: 15px;
}

.etran-address > .ant-form-item-label {
  font-size: 12px !important;
}

.etran-address > .ant-form-item {
  margin-bottom: 5px;
}

.etran-address > .ant-form-item-control {
  padding-top: 5px;
  width: 1%;
}

.etran-form-item {
  margin-bottom: 10px !important;
}

.etran-form-item > .ant-form-item-label {
  height: auto;
  width: 14vw;
}

.etran-form-item > .ant-form-item-label > label {
  width: 100%;
  height: auto;
  text-align: left;
  white-space: normal;
  font-size: 13px !important;
}

.etran-select-selector > .ant-select-selector {
  font-size: 12px !important;
  background-color: #ced0f9 !important;
}

.etran-row > .ant-row {
  height: auto;
}

.etran-extra > .ant-form-item-control {
  padding-top: 5px;
  align-items: center;
  display: flex;
  flex-flow: row;
}

.etran-form-item > .ant-form-item-control {
  padding-top: 5px;
  justify-content: center;
}

.etran-extra > .ant-form-item-control,
.etran-extra > .ant-form-item-extra {
  width: 60px !important;
  order: 0;
  margin-right: 2px;
}

.etran-extra {
  font-size: 12px !important;
}

.etran-extra > .ant-form-item-control > .ant-form-item-control-input {
  width: 100%;
  height: 27px;
  padding-top: 0px !important;
  margin-right: 2px;
  order: 1;
}

.etran-extra > .ant-form-item-control > .ant-form-item-extra > .ant-input-sm {
  height: 25px;
  margin: 0 !important;
  padding: 1px !important;
  font-size: 12px !important;
  text-align: center !important;
}

.etran-extra > .ant-form-item-control > .ant-form-item-extra > input {
  cursor: default !important;
}

.etran-modal > .ant-modal-content > .ant-modal-footer {
  text-align: start !important;
}

.ant-page-header-ghost {
  background-color: white !important;
}

.etran-form-item > .ant-form-item-control-input-content {
  background-color: #ced0f9 !important;
  height: 27px;
}

.etran-form-item > .ant-col > .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-select > .ant-select-selector {
  background-color: #ced0f9 !important;
  height: 27px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.etran-form-input {
  height: 27px;
  background-color: #ced0f9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.ant-form-item-extra > input {
  height: 27px;
  background-color: #fffee0;
  border: 1px solid #000000;
}

.etranStatusViewer > .ant-popover-content {
  padding-top: 18px !important;
  padding-left: 18px !important;
}

.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  margin-bottom: 0px;
  vertical-align: top;
}

.send > .ant-col {
  justify-content: center;
}

.slider > .ant-slider-vertical {
  margin: auto auto !important;
}

.slider {
  align-items: center;
  text-align: center;
}

.points {
  border: #515769 1px solid;
}

.ant-table-content > .table {
  table,
  th,
  td {
    border: 2px solid;
  }
}

.main {
  background: #ffffff !important;
}

.chapter {
  font-weight: bold;
  font-size: 16px;
}

.right {
  float: right !important;
}

.tariffCalculationTable.fgk > div > div {
  white-space: pre-wrap;
  height: 5rem;
}

.claim-button-container {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: var(--gap);
}

.button-container-right {
  display: flex;
  padding: var(--gap) 0 var(--gap) 0;
  justify-content: flex-end;
  align-items: center;
  align-content: space-around;
  flex-wrap: nowrap;
  gap: var(--gap);
}

.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.dx-button-mode-contained.dx-button:not([disabled]):hover
  > .anticon.dispatcher-icon-gray
  svg {
  fill: #f69c00;
}

.ant-btn-link:not([disabled]):hover,
.ant-btn-link:not([disabled]):focus {
  color: #f69c00;
  border-color: transparent;
  background: transparent;
}

.reports-card {
  padding: 0;
}

.reports-card > .ant-card-body {
  display: grid;
  grid-gap: var(--gap);
}

.radio-group-shipping {
  display: grid;
  grid-template-areas: 'rg-a rg-b rg-c';
  font-family: OpenSans-Semibold;
  font-size: 14px;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: var(--gap);
}

.shipping-sends-arrival .ant-card-extra {
  margin-left: unset;
}

.rg-a {
  grid-area: rg-a;
  text-overflow: ellipsis;
}

.rg-b {
  grid-area: rg-b;
  text-overflow: ellipsis;
}

.rg-c {
  grid-area: rg-c;
  text-overflow: ellipsis;
}

.shipping-sends-arrival > .ant-card-head > .ant-card-head-wrapper {
  display: flex;
  align-content: center;
  gap: var(--gap);
}

.ant-row {
  row-gap: var(--gap) !important;
}

.ant-form-large .ant-form-item-label > label[for='send-editor_tags'] {
  height: unset !important;
}

.sends-tag-editor > .ant-col.ant-form-item-control > .ant-form-item-control-input {
  min-height: unset !important;
}

.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-lg > input {
  min-height: 40px;
  background: transparent;
}

.ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > input {
  min-height: 40px;
}

.ant-select-lg,
.ant-input-lg {
  font-size: 14px;
}

.ant-select.ant-select-lg.ant-select-status-success > label {
  font-size: 12px;
}

.ant-select.ant-select-lg:not([class*='ant-select-status-success']) > label {
  font-size: 14px;
}

.ant-form label {
  width: 100%;
  padding-right: 40px;
}

.ant-form .required-placeholder span.required-mark {
  color: rgb(235, 34, 34);
}

.ant-form-item-control-input-content > div > label > div > p {
  white-space: nowrap;
  overflow: hidden;
  padding: 0 5px 0 0;
  text-overflow: ellipsis;
}

.ant-tag-success {
  background: #008000;
}

.ant-tabs-tabpane {
  flex-wrap: wrap;
  align-content: space-around;
  align-items: center;
  column-gap: var(--tabgap);
  row-gap: var(--tabgap);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  column-gap: var(--tabgap);
  row-gap: var(--tabgap);
}

.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 16px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: unset;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 0 12px;
  text-align: center;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: unset;
}

.placeholder-gng {
  display: grid;
  grid-template-columns: minmax(1px, 374px) 1fr;
}

.placeholder-gng > span {
  text-align: left;
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  align-items: center;
  column-gap: var(--gap);
  row-gap: var(--gap);
}

.avatar-uploader .ant-upload-select-picture-card {
  height: 80px;
  width: 80px;
}

.company-source-editor {
  align-items: center;
  justify-content: space-between;
}

.company-source-editor > .ant-col > .ant-checkbox-wrapper {
  width: 100%;
  margin-left: unset;
  padding-right: unset;
  display: flex;
  justify-content: center;
}

.company-source-editor > .ant-col > .supports-wrapper {
  padding: 4px;
}

.company-source-editor > .ant-col > div > .ant-checkbox-wrapper {
  width: 100%;
  margin-left: unset;
  padding-right: unset;
}

.schedule-card > .ant-card-actions > li > span:hover {
  color: unset;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-input-group-wrapper.ant-input-search.ant-input-search-large.ant-input-group-wrapper-lg
  > .ant-input-wrapper.ant-input-group
  > .ant-input.ant-input-lg {
  height: 40px;
}

@media screen and (min-width: 1px) {
  * {
    --gap: 2px;
    --halfgap: 1px;
    --tabgap: 4px;
  }
}

//xs
@media screen and (min-width: 500px) {
  * {
    --gap: 2px;
    --halfgap: 1px;
    --tabgap: 8px;
  }
}

//sm
@media screen and (min-width: 576px) {
  * {
    --gap: 4px;
    --halfgap: 1px;
    --tabgap: 10px;
  }
}

//md
@media screen and (min-width: 768px) {
  * {
    --gap: 8px;
    --halfgap: 4px;
    --tabgap: 12px;
  }
}

//lg
@media screen and (min-width: 992px) {
  * {
    --gap: 8px;
    --halfgap: 4px;
    --tabgap: 14px;
  }
}

//xl
@media screen and (min-width: 1200px) {
  * {
    --gap: 8px;
    --halfgap: 4px;
    --tabgap: 16px;
  }
}

//xxl
@media screen and (min-width: 1600px) {
  * {
    --gap: 8px;
    --halfgap: 4px;
    --tabgap: 20px;
  }
}

@media screen and (max-width: 47rem) {
  * {
    --card-padding: 1rem;
    --padding: 4.6rem;
    --gap: 2px;
    --halfgap: 1px;
  }

  .radio-group-shipping {
    grid-template-areas: 'rg-a rg-c' 'rg-b rg-c';
  }

  .shipping-sends-arrival > .ant-card-head > .ant-card-head-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: var(--gap);
  }

  .verticalTable {
    padding: 1rem;
  }

  .verticalTable > div {
    padding: 0rem;
  }

  .node {
    padding: 0.2rem 0 0 0;
  }

  aside {
    z-index: 2;
  }

  h1 {
    font-size: 20px !important;
  }

  .ant-card {
    //padding: 16px !important;
    background: #fff !important;
  }

  .ant-card-mobile > .ant-card > .ant-card-bordered {
    border-radius: 0.5rem !important;
    border: 1px solid #dedede;
  }

  .ant-card-mobile > .ant-card.ant-card-bordered > .ant-card-body > .ant-row {
    gap: 4px !important;
  }

  .ant-page-header.page_header.ant-page-header-ghost.ant-page-header-compact {
    padding-left: 3rem;
  }

  .result-card .ant-card {
    padding: 0 !important;
  }

  .ant-card-head-title {
    display: inline-block;
    flex: 1 1;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-card-head {
    min-height: 1.9rem;
    margin-bottom: -1px;
    padding: 2px 6px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 1rem;
    background: transparent;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
  }

  .ant-card-extra {
    padding: 0;
  }

  .ant-page-header-compact .ant-page-header-heading {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
  }

  .ant-page-header-heading-title {
    margin-right: 0;
    font-family: OpenSans-bold;
    font-size: 100%;
    text-overflow: ellipsis;
    width: calc(100vw - 5.2rem);
    font-weight: normal;
    max-width: fit-content;
  }

  .ant-page-header-heading-sub-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 0.1rem;
  }

  .ant-list-lg .ant-list-item {
    padding: 0.2rem;
  }

  .ant-list-bordered.ant-list-lg .ant-list-item {
    padding: 0.2rem;
  }

  .ant-modal-body {
    padding: 0.25rem;
  }

  .ant-radio-wrapper {
    display: table;
    vertical-align: middle;
    padding: 0.1rem;
  }

  .tariffTable tr td:first-child {
    padding-left: 0.25rem !important;
  }

  .tariffTable th {
    height: 2.4rem;
  }

  .ant-modal {
    max-width: 100vw;
    margin: 0;
  }

  .invCountry {
    display: block;
    flex: 0 0 93%;
    max-width: 930%;
  }

  .invCountry > .ant-col.ant-form-item-control > .ant-form-item-control-input {
    position: relative;
    min-height: 1px;
    display: inline-block;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .invCountry > .ant-col.ant-form-item-control > .ant-form-item-extra {
    position: relative;
    min-height: 1px;
    display: inline-block;
    padding-top: 0;
    padding-left: 5px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    color: black;
  }

  .invCountryExtra {
    background-color: #ffffde;
    border: solid 1px #cbcbd4;
    border-radius: 0px;
    padding: 0 0.5rem;
    min-width: 80px;
    height: 24px;
    color: black;
  }

  .chapter {
    padding-left: 0;
    font-family: 'OpenSans-Semibold';
    font-size: 20px;
  }

  .ant-card-body {
    padding: 0.25rem;
  }

  .etran-form-route {
    width: 100%;
  }

  .etran-form-route .ant-form-item-control-input-content {
    .ant-col {
      padding: 0.5rem 0.313rem 0 0;
    }

    .ant-row {
      width: 100%;
    }

    display: unset;
    flex: auto;
    width: 100%;
    justify-content: flex-start;
  }

  .etran-modal > .ant-modal-content > .ant-modal-body > div {
    overflow: hidden auto;
    max-height: calc(100vh - 108px) !important;
  }

  .ant-modal {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    pointer-events: none;
    position: relative;
    top: 0px;
    width: 100vw !important;
    max-width: 100vw !important;
    max-height: 100vh !important;
    transform-origin: 487.094px 292px;
    //max-width: calc(100vw - 32px);
    margin: 0 auto;
    padding-bottom: 0;
  }

  .park-widget-table {
    max-height: calc(100vh - 3.8rem);
    width: 100%;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    display: flex;
    margin: 0;
    padding: 1rem 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
    border-top-color: rgba(0, 0, 0, 0.06);
  }

  .tariffTableMobile.fgk tr {
    width: 100%;
    text-align: left;
    min-height: 3rem;
    padding: 0 0.5rem;
    border: 1px solid #dedddd;
    display: inline-block;
  }

  .tariffTableMobile.fgk tr:nth-child(1) {
    min-height: 2rem;
  }

  .tariffTableMobile.fgk tr:nth-child(3) {
    min-height: 2rem;
  }

  .tariffTableMobile.fgk tr:nth-child(4) {
    min-height: 2rem;
  }

  .tariffTableMobile.fgk tr:nth-child(5) {
    min-height: 2rem;
  }

  .tariffTableMobile.fgk tr:nth-child(6) {
    min-height: 2rem;
  }

  .tariffTable tr td {
    padding: 1px !important;
    font-size: 13px;
  }

  .leaflet-container {
    z-index: 0;
  }

  .dispatcher-card {
    background-color: #f0f2f5 !important;
  }

  .dispatcher-card > .ant-card-actions {
    background-color: #f0f2f5;
  }

  .dispatcher-card.ant-card-hoverable {
    transition: background-color 0.3s !important;
  }

  .dispatcher-card.ant-card-hoverable:hover {
    background-color: white !important;
  }

  .result-card.ant-card {
    padding: 0 !important;
  }

  .result-card {
    min-width: unset;
  }

  .total {
    grid-template-columns: 100%;
  }

  .tariffCalculationTable.parameters {
    grid-template-columns: 30% 16% 54%;
    min-width: unset;
    max-width: unset;
  }

  .tariffCalculationTable.parameters > div > div {
    white-space: pre-wrap;
    justify-content: left;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 9px;
  }

  .way > div {
    font-size: 9px;
  }
}

.ant-table-tbody > tr > td {
  font-family: 'OpenSans-Semibold';
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-thead > tr > th {
  font-family: 'OpenSans-Semibold';
}

.ant-btn {
  font-family: 'OpenSans-Semibold';
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 1px;
}

.ant-popover.ant-popover-placement-bottom .ant-form-item-control-input {
  padding: 5px 5px;
}

.send-params {
  width: calc(95vw - 288px);
}

.send-params-collapsed {
  padding: var(--gap);
  width: calc(95vw - 80px);
}

.send-params-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  align-self: start;
}

.send-params-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title > .dx-group-cell > div {
  padding: var(--gap);
}

.send-params-title {
  display: grid;
}

.send-params-title > div {
  display: grid;
  padding: var(--gap);
}

.send-params-title > div > div {
  font-size: 12px;
  padding: var(--gap);
}

.send-params-title > div > div:nth-of-type(odd) {
  font-weight: bold;
}

@media print {
  .ant-page-header {
    display: none;
  }

  .ant-layout-sider {
    display: none;
  }

  .ant-card-extra {
    display: none;
  }

  .anticon {
    display: none !important;
  }

  .ant-select-selector {
    border: 0 !important;
  }

  .print-logo {
    display: block !important;
    text-align: right;
  }

  .tariff-container {
    overflow: hidden !important;
    height: 110% !important;
  }

  .ant-layout-content > div {
    overflow: hidden !important;
  }

  .ant-layout {
    height: 110%;
  }

  body {
    zoom: 60%;
  }

  html {
    height: 100% !important;
  }
}
