$purple-50: #2b8ede !default;
$purple-100: #2289dd !default;
$purple-200: #2183d4 !default;
$purple-300: #207ecb !default;
$purple-400: #1e78c2 !default;
$purple-500: #1d71b8 !default;
$purple-600: #1c6db0 !default;
$purple-700: #1a68a8 !default;
$purple-800: #19639f !default;
$purple-900: #175d96 !default;
$purple-A100: #16588d !default;
$purple-A200: #155284 !default;
$purple-A400: #134d7c !default;
$purple-A700: #124773 !default;
$purple: $purple-500 !default;
$brand-primary: $purple !default;

.etranContent {
  padding: 10px;

  label,
  div {
    text-align: start;
    color: black !important;
    font-size: 12px;
  }

  .comboEdit {
    background-color: #ced0f9;
    border: solid 1px black;
    padding: 2px 40px 2px 4px;
    margin-right: 5px;
  }

  .textEdit {
    background-color: #fffee0;
    border: solid 1px black;
    padding: 2px 40px 2px 4px;
    margin-right: 5px;
  }

  .etranTable {
    font-size: 12px;
    caption {
      background-color: #00867d;
      color: white;
      text-align: center;
      padding: 2px;
      font-weight: bold;
    }
    thead {
      tr {
        color: black;
        background-color: #f1f1f1;
        th {
          font-weight: normal !important;
          white-space: normal !important;
          padding: 1px;
        }
      }
    }
    tbody {
      tr {
        background-color: #fdcfb8;
        td {
          padding: 1px;
        }
      }
    }
  }
}

.etranTableInvoice {
  font-size: 12px;
  caption {
    background-color: #00867d;
    color: white;
    text-align: center;
    padding: 2px;
    font-weight: bold;
    caption-side: top !important;
  }
  thead {
    tr {
      color: black;
      background-color: #f1f1f1;
      th {
        font-weight: normal !important;
        white-space: normal !important;
        padding: 1px;
        border: 1px solid darkgrey;
      }
    }
  }
  tbody {
    tr {
      background-color: #fdcfb8;
      td {
        padding: 1px;
        border: 1px solid darkgrey;
      }
    }
  }
}

.colFormLabel {
  padding: 0 5px 6px 0;
  text-align: right;
}

.mainPanel .mainContent {
  margin-top: 0px;
}
.mainContent {
  padding: 10px !important;
}

.full-width {
  width: 100%;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: auto 4px;
  &.green {
    background-color: #38c172;
  }
  &.yellow {
    background-color: #f2d024;
  }
}

.nav > li > a {
  padding: 5px 15px;
}

.sidebar-moving-tab {
  padding: 5px 15px;
}

.containerFluid:after,
.container:after,
.dlHorizontal dd:after,
.formHorizontal .form-group:after,
.modalFooter:after,
.modalHeader:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panelBody:after,
.row:after {
  clear: both;
}

.containerFluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.row:after,
.row:before {
  display: table;
  content: ' ';
}

.row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row-no-gutters [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.colMd1,
.colMd10,
.colMd11,
.colMd12,
.colMd2,
.colMd3,
.colMd4,
.colMd5,
.colMd6,
.colMd7,
.colMd8,
.colMd9,
.colSm1,
.colSm10,
.colSm11,
.colSm12,
.colSm2,
.colSm3,
.colSm4,
.colSm5,
.colSm6,
.colSm7,
.colSm8,
.colSm9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

@media (min-width: 768px) {
  .colSm1,
  .colSm10,
  .colSm11,
  .colSm12,
  .colSm2,
  .colSm3,
  .colSm4,
  .colSm5,
  .colSm6,
  .colSm7,
  .colSm8,
  .colSm9 {
    float: left;
  }
  .colSm12 {
    width: 100%;
  }
  .colSm11 {
    width: 91.66666667%;
  }
  .colSm10 {
    width: 83.33333333%;
  }
  .colSm9 {
    width: 75%;
  }
  .colSm8 {
    width: 66.66666667%;
  }
  .colSm7 {
    width: 58.33333333%;
  }
  .colSm6 {
    width: 50%;
  }
  .colSm5 {
    width: 41.66666667%;
  }
  .colSm4 {
    width: 33.33333333%;
  }
  .colSm3 {
    width: 25%;
  }
  .colSm2 {
    width: 16.66666667%;
  }
  .colSm1 {
    width: 8.33333333%;
  }
  .colSmpull-12 {
    right: 100%;
  }
  .colSmpull-11 {
    right: 91.66666667%;
  }
  .colSmpull-10 {
    right: 83.33333333%;
  }
  .colSmpull-9 {
    right: 75%;
  }
  .colSmpull-8 {
    right: 66.66666667%;
  }
  .colSmpull-7 {
    right: 58.33333333%;
  }
  .colSmpull-6 {
    right: 50%;
  }
  .colSmpull-5 {
    right: 41.66666667%;
  }
  .colSmpull-4 {
    right: 33.33333333%;
  }
  .colSmpull-3 {
    right: 25%;
  }
  .colSmpull-2 {
    right: 16.66666667%;
  }
  .colSmpull-1 {
    right: 8.33333333%;
  }
  .colSmpull-0 {
    right: auto;
  }
  .colSmpush-12 {
    left: 100%;
  }
  .colSmpush-11 {
    left: 91.66666667%;
  }
  .colSmpush-10 {
    left: 83.33333333%;
  }
  .colSmpush-9 {
    left: 75%;
  }
  .colSmpush-8 {
    left: 66.66666667%;
  }
  .colSmpush-7 {
    left: 58.33333333%;
  }
  .colSmpush-6 {
    left: 50%;
  }
  .colSmpush-5 {
    left: 41.66666667%;
  }
  .colSmpush-4 {
    left: 33.33333333%;
  }
  .colSmpush-3 {
    left: 25%;
  }
  .colSmpush-2 {
    left: 16.66666667%;
  }
  .colSmpush-1 {
    left: 8.33333333%;
  }
  .colSmpush-0 {
    left: auto;
  }
  .colSmoffset-12 {
    margin-left: 100%;
  }
  .colSmoffset-11 {
    margin-left: 91.66666667%;
  }
  .colSmoffset-10 {
    margin-left: 83.33333333%;
  }
  .colSmoffset-9 {
    margin-left: 75%;
  }
  .colSmoffset-8 {
    margin-left: 66.66666667%;
  }
  .colSmoffset-7 {
    margin-left: 58.33333333%;
  }
  .colSmoffset-6 {
    margin-left: 50%;
  }
  .colSmoffset-5 {
    margin-left: 41.66666667%;
  }
  .colSmoffset-4 {
    margin-left: 33.33333333%;
  }
  .colSmoffset-3 {
    margin-left: 25%;
  }
  .colSmoffset-2 {
    margin-left: 16.66666667%;
  }
  .colSmoffset-1 {
    margin-left: 8.33333333%;
  }
  .colSmoffset-0 {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .colMd1,
  .colMd10,
  .colMd11,
  .colMd12,
  .colMd2,
  .colMd3,
  .colMd4,
  .colMd5,
  .colMd6,
  .colMd7,
  .colMd8,
  .colMd9 {
    float: left;
  }
  .colMd12 {
    width: 100%;
  }
  .colMd11 {
    width: 91.66666667%;
  }
  .colMd10 {
    width: 83.33333333%;
  }
  .colMd9 {
    width: 75%;
  }
  .colMd8 {
    width: 66.66666667%;
  }
  .colMd7 {
    width: 58.33333333%;
  }
  .colMd6 {
    width: 50%;
  }
  .colMd5 {
    width: 41.66666667%;
  }
  .colMd4 {
    width: 33.33333333%;
  }
  .colMd3 {
    width: 25%;
  }
  .colMd2 {
    width: 16.66666667%;
  }
  .colMd1 {
    width: 8.33333333%;
  }
  .colMdpull-12 {
    right: 100%;
  }
  .colMdpull-11 {
    right: 91.66666667%;
  }
  .colMdpull-10 {
    right: 83.33333333%;
  }
  .colMdpull-9 {
    right: 75%;
  }
  .colMdpull-8 {
    right: 66.66666667%;
  }
  .colMdpull-7 {
    right: 58.33333333%;
  }
  .colMdpull-6 {
    right: 50%;
  }
  .colMdpull-5 {
    right: 41.66666667%;
  }
  .colMdpull-4 {
    right: 33.33333333%;
  }
  .colMdpull-3 {
    right: 25%;
  }
  .colMdpull-2 {
    right: 16.66666667%;
  }
  .colMdpull-1 {
    right: 8.33333333%;
  }
  .colMdpull-0 {
    right: auto;
  }
  .colMdpush-12 {
    left: 100%;
  }
  .colMdpush-11 {
    left: 91.66666667%;
  }
  .colMdpush-10 {
    left: 83.33333333%;
  }
  .colMdpush-9 {
    left: 75%;
  }
  .colMdpush-8 {
    left: 66.66666667%;
  }
  .colMdpush-7 {
    left: 58.33333333%;
  }
  .colMdpush-6 {
    left: 50%;
  }
  .colMdpush-5 {
    left: 41.66666667%;
  }
  .colMdpush-4 {
    left: 33.33333333%;
  }
  .colMdpush-3 {
    left: 25%;
  }
  .colMdpush-2 {
    left: 16.66666667%;
  }
  .colMdpush-1 {
    left: 8.33333333%;
  }
  .colMdpush-0 {
    left: auto;
  }
  .colMdoffset-12 {
    margin-left: 100%;
  }
  .colMdoffset-11 {
    margin-left: 91.66666667%;
  }
  .colMdoffset-10 {
    margin-left: 83.33333333%;
  }
  .colMdoffset-9 {
    margin-left: 75%;
  }
  .colMdoffset-8 {
    margin-left: 66.66666667%;
  }
  .colMdoffset-7 {
    margin-left: 58.33333333%;
  }
  .colMdoffset-6 {
    margin-left: 50%;
  }
  .colMdoffset-5 {
    margin-left: 41.66666667%;
  }
  .colMdoffset-4 {
    margin-left: 33.33333333%;
  }
  .colMdoffset-3 {
    margin-left: 25%;
  }
  .colMdoffset-2 {
    margin-left: 16.66666667%;
  }
  .colMdoffset-1 {
    margin-left: 8.33333333%;
  }
  .colMdoffset-0 {
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
}

.textPrimary {
  color: $brand-primary;
}

.etranTableInvoice {
  th {
    padding: 2px !important;
  }
  td {
    padding: 2px !important;
  }
}

.tableResponsive {
  min-height: 0.01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .tableResponsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .tableResponsive > .table {
    margin-bottom: 0;
  }
  .tableResponsive > .table > tbody > tr > td,
  .tableResponsive > .table > tbody > tr > th,
  .tableResponsive > .table > tfoot > tr > td,
  .tableResponsive > .table > tfoot > tr > th,
  .tableResponsive > .table > thead > tr > td,
  .tableResponsive > .table > thead > tr > th {
    white-space: nowrap;
  }
  .tableResponsive > .table-bordered {
    border: 0;
  }
  .tableResponsive > .table-bordered > tbody > tr > td:first-child,
  .tableResponsive > .table-bordered > tbody > tr > th:first-child,
  .tableResponsive > .table-bordered > tfoot > tr > td:first-child,
  .tableResponsive > .table-bordered > tfoot > tr > th:first-child,
  .tableResponsive > .table-bordered > thead > tr > td:first-child,
  .tableResponsive > .table-bordered > thead > tr > th:first-child {
    border-left: 0;
  }
  .tableResponsive > .table-bordered > tbody > tr > td:last-child,
  .tableResponsive > .table-bordered > tbody > tr > th:last-child,
  .tableResponsive > .table-bordered > tfoot > tr > td:last-child,
  .tableResponsive > .table-bordered > tfoot > tr > th:last-child,
  .tableResponsive > .table-bordered > thead > tr > td:last-child,
  .tableResponsive > .table-bordered > thead > tr > th:last-child {
    border-right: 0;
  }
  .tableResponsive > .table-bordered > tbody > tr:last-child > td,
  .tableResponsive > .table-bordered > tbody > tr:last-child > th,
  .tableResponsive > .table-bordered > tfoot > tr:last-child > td,
  .tableResponsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0;
  }
}
