.content {
  margin: 10px;
}
.v-align-m {
  td {
    vertical-align: middle !important;
  }
  tr[aria-level='0'] {
    background: #e6e6e6;
  }
  td.text-a-c {
    text-align: center !important;
  }
}
