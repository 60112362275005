.custom-calendar {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__radio-list {
    display: flex;

    .ant-radio-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  &__calendar {
  }
}

.my-custom-calendar {
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 15px;
  &__calendar-range {
    align-self: flex-start;
  }
  &__calendar {
    display: flex;
    gap: 5px;
  }
  &__radio-list {
    display: flex;

    .ant-radio-wrapper {
      display: flex;
      align-items: center;
    }
  }
  &__calendar {
  }
}
