.statusIlsTitle {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0;
  .client {
  }
  .claim {
  }
  .status {
    font-size: 18px;
    //margin-left: 10px;
    color: #4977e9;
    background-color: #f1f2f6;
    padding: 0 10px;
  }
}

.requestIlsItemPage {
  height: calc(100vh - 64px - var(--header-height));

  .requestPeriod {
    color: #aaa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .wrapperBtn {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .approveBtn {
      color: #00b549;
      border-color: #00b549;
    }

    .rejectBtn {
      color: #d40000;
      border-color: #d40000;
    }
    .returnBtn {
      color: #fa8c16;
      border-color: #fa8c16;
    }
  }

  .listParams {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .itemParams {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 20px;

      p {
        margin: 0;
        color: #242424;
        font-size: 16px;
      }
    }
  }

  .wagonsWrapper {
    display: flex;
    gap: 50px;
    font-size: 16px;

    p {
      margin: 0;
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
        color: #4977e9;
        background-color: #f1f2f6;
        padding: 0 10px;
      }
    }

    .addSend {
      margin-left: auto;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      gap: 10px;

      color: #4977e9;
      border-color: #4977e9;

      &:disabled {
        color: #dedede;
        border-color: #dedede;
      }
      span {
        margin: 0;
      }
    }
  }
}
