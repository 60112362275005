.RequestIlsForm.ant-modal {
  //max-width: 90vw;
  //padding: 0 10px;
  //overflow: hidden;
  //width: 100%;

  .ant-modal-body {
    overflow-y: auto;
    height: 80vh;
    padding: 10px;
  }

  .status {
    color: #000;

    span {
      color: #aaa;
      font-size: 16px;
      border-bottom: 1px solid #aaa;
    }

    &.request .request {
      color: #000;
    }

    &.sending .sending {
      color: #000;
    }
  }

  .ilsForm {
    margin-top: 20px;
  }

  .ilsInputs {
    display: grid;
    grid-template-columns: 4fr 5fr;
    gap: 10px;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }

    .form-element {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.RequestIlsForm .ilsFormSending {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .grid {
    display: grid;
    gap: 20px;

    &.col-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.col-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.col-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .ant-radio-wrapper {
    padding: 0;
    display: flex;
    align-items: center;
    width: auto;
  }

  .ant-picker-cell {
    text-align: end;
    vertical-align: top;
    border: 1px solid #ccc;

    .calendar-cell {
      min-height: 90px;
      padding: 0 5px;

      .cell-calendar-form-item {
        .ant-form-item-row {
          display: grid;
          grid-template-columns: 2fr 1fr;
          width: 100%;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }

  .parent-etsng-group {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0 0 16px;

    .placeholder {
      position: absolute;
      left: 16px;
      top: 14px;
      transition: 0.2s ease all;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #808c9a;
    }
  }
  .parent-etsng-group.selected {
    .placeholder {
      top: 6px;
      font-size: 12px !important;
    }
  }
}
