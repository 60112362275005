.ant-picker-cell {
  text-align: end;
  vertical-align: top;
  border: 1px solid #ccc;

  .calendar-cell {
    min-height: 50px;
    padding: 0 5px;
  }
}
